import React, { Component } from "react"
import { Container } from "flux/utils"

import Icon from '../components/Icon'
import Name from "../components/Name";
import Personel from '../components/Personel'
import StatusLamp from '../components/StatusLamp'


import { keys as connectionKeys } from '../actions/ConnectionActions'
import { keys as humanChatStatusKeys } from '../actions/HumanChatStatusActions'

import IconStore from '../stores/IconStore'
import NameStore from '../stores/NameStore'
import PersonelStore from '../stores/PersonelStore'
import HumanChatStatusStore from "../stores/HumanChatStatusStore";
import ConnectionStore from "../stores/ConnectionStore";

class ProfileContainer extends Component {
  static getStores() {
    return [
      IconStore,
      HumanChatStatusStore,
      ConnectionStore,
      PersonelStore,
      NameStore,
    ]
  }
  static calculateState(prevState) {
    return {
      icon: IconStore.getState(),
      humanChatStatus: HumanChatStatusStore.getState(),
      connection: ConnectionStore.getState(),
      personel: PersonelStore.getState(),
      name: NameStore.getState(),
    }
  }

  render() {
    const isDisplayStatus = ![humanChatStatusKeys.isDisconnectedByAgent,humanChatStatusKeys.isDisconnectedByUser].includes(this.state.humanChatStatus.type)
    const status = (connectionKeys.connect === this.state.connection.type) && ([humanChatStatusKeys.initialize,humanChatStatusKeys.isConnected].includes(this.state.humanChatStatus.type))?'online':'offline'
    return (
      <div className="profile profile--bot">
        <Icon {...this.state.icon} />
        <StatusLamp status={status} isDisplayStatus={isDisplayStatus} />
        <Personel {...this.state.personel} />
        <Name {...this.state.name} />
      </div>
    )
  }
}

export default Container.create(ProfileContainer)