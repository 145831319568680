import React from "react"
import BotCarouselMessage from "./BotCarouselMessage"
import BotSuggestedActions from "./BotSuggestedActions"
import BotMessageDef from "./BotMessageDef"
import moment from "moment"


export default ({ now, icon="", iconRadius="", iconBg="", device="", inlineStyle="", watermark, text='', type='', 
attachmentLayout='', attachments = [], disabled=false, suggestedActions,  balloonId, availability, scrollToBottom,
clickButton, clickLink}) => {
  const time = moment(now).format("HH:mm")
  if (attachmentLayout === "carousel") {
    return (
      <div style={{ height: "100%", overflow: "hidden" }} className={balloonId}>
        <BotCarouselMessage
          text={text}
          icon={icon}
          radius={iconRadius}
          bg={iconBg}
          attachments={attachments}
          device={device}
          disabled={disabled}
          time={time}
          inlineStyle={inlineStyle}
          watermark={watermark}
          clickButton={clickButton}
          clickLink={clickLink}
          balloonId={balloonId}
          scrollToBottom={scrollToBottom}
        />
        {(process.env.REACT_APP_CAROUSEL_SUGGEST_BALLOON && suggestedActions && suggestedActions.actions && suggestedActions.actions.length)?<BotSuggestedActions 
          icon={icon}
          buttons={suggestedActions.actions}
          time={time}
          disabled={disabled}
          clickButton={clickButton}
          clickLink={clickLink}
        />:null}
      </div>
    )
  } else {
    return (
      <div style={{ height: "100%", overflow: "hidden" }} className={balloonId}>
        {attachments.map((attachment, index) => 
          <BotMessageDef 
          icon={icon}
          isStart={index===0}
          radius={iconRadius}
          bg={iconBg}
          buttons={attachment.content.buttons}
          images={attachment.content.images}
          time={time}
          text={attachment.content.text}
          disabled={disabled}
          key={index} 
          clickButton={clickButton}
          clickLink={clickLink}
          balloonId={balloonId}
          availability={availability}
          scrollToBottom={scrollToBottom}
          />
        )}
      </div>
    )
  }
}
