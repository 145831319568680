import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/QueueActions"
import { settings } from "../config/settings"

class QueueStore extends ReduceStore {

  getInitialState() {
    return settings.queue
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.set:
        return action.queueState
      default:
        return state
    }
  }
}

export default new QueueStore(Dispatcher)
