import React from 'react';
import i18n from "../i18n";
import EmailValidator from 'email-validator';

import FormInputActions from '../actions/FormInputActions'
import FormErrMsgActions from '../actions/FormErrMsgActions'
import FormFormatActions from '../actions/FormFormatActions'

import FormInputFieldTextarea from './FormInputFieldTextarea';
import FormInputFieldInput from './FormInputFieldInput';
import FormInputFieldSelect from  './FormInputFieldSelect';
import FormInputFieldCheckBox from './FormInputFieldCheckBox';
import FormInputFieldPurchaseHistory from './FormInputFieldPurchaseHistory'

function checkPurchaseHistory(purchaseHistory) {
  return Array.isArray(purchaseHistory) && purchaseHistory.length > 0
}

export default ({ formInfo }) => {
  const {
    type = 'text',
    id,
    name,
    placeholder,
    value,
    errMsg,
    required = false,
    defaultValue,
    regexp,
    maxLength,
    initialValue,
    purchaseHistory,
    additionalMessageKey,
    skill,
    selectItemsKey
  } = formInfo
  const _isValidFormat = (value='') => {
    if ( value.length === 0 || ['checkbox','name'].includes(type) ) return true
    if ( type === 'email' ) return EmailValidator.validate(value);
    return Boolean(value.match(new RegExp(regexp)));
  }
  const _isInputValue = (value='') => {
    if (type === 'checkbox') return value
    const trimValue = value.trim();
    if (trimValue && trimValue.length > 0) return true;
    return false;
  }

  const _getValue = (event) => type === 'checkbox'
    ? event.target.checked || false
    : event.target.value || ''

  const _setErrorMessage = (value) => {
    const isInput = (required)?_isInputValue(value):true;
    const isValidFormat = _isValidFormat(value);
    if (isInput && isValidFormat){
      FormFormatActions.valid(id, true)
      return FormErrMsgActions.change(id, '');
    }
    FormFormatActions.valid(id, false)
    if (!isInput){
      return FormErrMsgActions.change(id, i18n.t(`modal.errorMessage.required`));
    }
    if (!isValidFormat){
      return FormErrMsgActions.change(id, i18n.t(`modal.errorMessage.format`));
    }
  }

  const onChange = (event) => {
    const value = _getValue(event)
    FormInputActions.change(id,value);
    if (id === 'loginUserInquiryCategory') FormFormatActions.setSkill(value)
    _setErrorMessage(value)
  }

  const onChangeForPurchaseHistory = (value) => {
    FormInputActions.change(id, value)
  }

  const onBlur = (event) => {
    const value = _getValue(event)
    _setErrorMessage(value)
  }

  // const format = `modal.form.${name}`
  // const translatedName = i18n.t(`${format}.name`) || ''
  // const translatedPlaceholder = i18n.t(`${format}.placeholder`) || ''
  const requiredMark = (required) ? <span>*</span> : ''
  const isError = Boolean(errMsg);

  const InputComponent = () => {
    const commonProps = {
      id,
      type,
      value: value || defaultValue,
      required,
      isSpace:!isError,
      maxLength,
      placeholder,
      onChange,
      onBlur
    }
    switch(type) {
      case 'textarea': {
        const additionalMessage =  additionalMessageKey && ['SHP', 'PAY', 'PNT', 'TEC'].includes(skill) && !checkPurchaseHistory(purchaseHistory)
          ? i18n.t(`modal.textareaAdditionalMessage.${additionalMessageKey}`)
          : ''
        return <FormInputFieldTextarea {...commonProps} additionalMessage={additionalMessage} />
      }
      case 'text':
      case 'tel':
      case 'email':
        return <FormInputFieldInput {...commonProps}/>
      case 'select':
        return <FormInputFieldSelect {...commonProps} initialValue={initialValue} selectItemsKey={selectItemsKey}/>
      case 'checkbox':
        return <FormInputFieldCheckBox {...commonProps} checkContent={i18n.t(`modal.checkBoxContent.${id}`)} />
      case 'purchaseHistory':
        return ['SHP', 'PAY', 'PNT', 'TEC'].includes(skill) && checkPurchaseHistory(purchaseHistory)
          ? <FormInputFieldPurchaseHistory {...commonProps} purchaseHistory={purchaseHistory} onChange={onChangeForPurchaseHistory} />
          : null
      default: return null;
    }
  }

  const LabelComponent = () => {
    switch (type) {
      case 'purchaseHistory':
        return ['SHP', 'PAY', 'PNT', 'TEC'].includes(skill) && checkPurchaseHistory(purchaseHistory)
          ? <label>{name}{requiredMark}</label>
          : null
      default: return <label>{name}{requiredMark}</label>
    }
  }

  return (
    <div className='modalInput'>
      {LabelComponent()}
      {InputComponent()}
      <span className={'errorMessage'} id={`${id}Error`} hidden={!isError}>{errMsg}</span>
    </div>
  );
}
