import React, { Component } from "react"
import { Container } from "flux/utils"
import i18n from "../i18n"
import { settings } from "../config/settings";

import HumanChatStatusStore from "../stores/HumanChatStatusStore";
import QueueStore from "../stores/QueueStore";
import DisplayQueueCategoryStore from '../stores/DisplayQueueCategoryStore'
import CaseStore from "../stores/CaseStore";
import SkillStore from "../stores/SkillStore";
class HumanChatStatusBarContainer extends Component {
  static getStores() {
    return [
      HumanChatStatusStore,
      QueueStore,
      DisplayQueueCategoryStore,
      CaseStore,
      SkillStore,
    ]
  }
  static calculateState(prevState) {
    return {
      case: CaseStore.getState(),
      skill: SkillStore.getState(),
      humanChatStatus: HumanChatStatusStore.getState(),
      queue: QueueStore.getState(),
      displayQueueCategory: DisplayQueueCategoryStore.getState(),
    }
  }

  render() {
    const {classification, text, data='',} = this.state.humanChatStatus
    const caseId = this.state.case
    const skill = this.state.skill === 'PAY'
      ? 'SHP'
      : this.state.skill
    const hyphen = " − "
    const queueIndex = this.state.queue ? this.state.queue.indexOf(`${skill}:${caseId}`) : null
    const count = queueIndex !== null
      ? queueIndex === -1
          ? hyphen
          : queueIndex
      : hyphen
    const maxQueueCount = process.env.REACT_APP_MAX_QUEUE_COUNT
    const replacedData = {
      data,
      queue: (maxQueueCount && this.state.queue && count >= maxQueueCount) ? maxQueueCount : count,
      more: this.state.queue && count >= maxQueueCount ? '以上' : ''
    };
    const translatedText = (text) ? i18n.t(text, replacedData) : []
    const checkedText = (settings.calcWaitCountType === 'CATEGORY' && !this.state.displayQueueCategory) ? translatedText.slice(0,1) : translatedText
    return (
      <div className={`humanChatStatusBar ${classification}`} key={text}>
      <div className={`humanChatStatusBar__inner ${classification}`}>
        <span className={`humanChatStatusBar__text ${classification}`}>{checkedText.map((text,index) => <p key={index}>{text}</p>)}</span>
        <button className={`humanChatStatusBar__btn ${classification}`}>{i18n.t('humanChatStatusMessage.reconnect')}</button>
      </div>
    </div>
    )
  }
}

export default Container.create(HumanChatStatusBarContainer)