import React, { Component } from "react"
import { Container } from "flux/utils"
import { debounce, throttle } from 'lodash'
import { isSmartPhone, isProduction, } from 'chat-common'

import InputStore from "../stores/InputStore"
import MessageStore from '../stores/MessageStore'
import ChatMessageStore from '../stores/ChatMessageStore'
import QuestionnaireStore from '../stores/QuestionnaireStore'
import UploadStore from '../stores/UploadStore'
import SubmitStore from '../stores/SubmitStore'
import TypingStore from '../stores/TypingStore'

import InputActions from "../actions/InputActions"
import SocketActions from "../actions/SocketActions"
import SubmitActions from "../actions/SubmitActions"
import SuggestionActions from '../actions/SuggestionActions'

import SubmitButton from "../components/SubmitButton"
import UploadButton from "../components/UploadButton"
import InputField from "../components/InputField"
import TypingStatus from "../components/TypingStatus"
import SessionStore from "../stores/SessionStore"
import CaseStore from "../stores/CaseStore"
import StaffStore from "../stores/StaffStore"

class InputContainer extends Component {
  static getStores() {
    return [
      InputStore,
      MessageStore,
      ChatMessageStore,
      QuestionnaireStore,
      UploadStore,
      SubmitStore,
      TypingStore,
      SessionStore,
      CaseStore,
      StaffStore,
    ]
  }

  static calculateState() {
    return {
      input: InputStore.getState(),
      message: MessageStore.getState(),
      chatMessage: ChatMessageStore.getState(),
      questionnaire: QuestionnaireStore.getState(),
      upload: UploadStore.getState(),
      submit: SubmitStore.getState(),
      typing: TypingStore.getState(),
      session: SessionStore.getState(),
      case: CaseStore.getState(),
      staff: StaffStore.getState(),
    }
  }

  onChange = (event) => {
    //Enterを押すと、改行のみのinputが残るので無視。
    const value = (event.target.value === '\n')?'':event.target.value;
    InputActions.input(value)
    switch(this.state.submit.opponent){
      case 'operator':
        return this.handleSneakPeek(this.state.case,this.state.staff,value)
      case 'bot':
        return this.handleSuggestion()
      default:
        return
    }
  }

  handleSneakPeek = throttle((caseId,staffId,text)=>{
    SocketActions.sendUserTextAsSneakPeak(caseId,staffId,text)
  },500)

  submit = () => {
    this.postMessage(this.state.input.value, 'submit', this.state.session)
    switch(this.state.submit.opponent){
      case 'operator':
        this.handleSneakPeek(this.state.case,this.state.staff,'')
        break
      default:
        return
    }
  }

  //this function is not used
  clickButton = (event) => {
    this.postMessage(event.target.value, 'button')
  }

  postMessage = (text, place, session) => {
    if(this.state.submit.opponent==='bot'){
      const watermark = this.state.message.length
      const timing = (watermark === 0)?'exception':this.state.message.timing;
      SocketActions.report({timing, place, text, action:'post-message'});
    }
    const hostname = window.location.hostname;
    const isPro = isProduction(hostname);
    const messages = this.state.chatMessage.contents || []
    const lastFrom = messages.length && messages[messages.length - 1].from
    SubmitActions.submit(text, 'text', this.state.submit, this.state.message.contents, this.state.questionnaire, undefined, isPro, session, lastFrom)
  }

  onKeyPress = (event) => {
    if(!isSmartPhone(navigator.userAgent)){
      if (!event.shiftKey && event.key === "Enter") {
        this.submit()
      }
    }
  }

  handleSuggestion = debounce(() => {
    if (this.state.input.value && this.state.input.value.length > 1 && this.state.input.value.length <= 30) {
      SocketActions.getSuggestion(this.state.input.value)
    } else {
      SuggestionActions.showDefaultItem();
    }
  }, 500)

  onTyping = () =>{
    if(this.state.submit.opponent === 'operator')
      SocketActions.sendUserTyping()
  }

  onNotTyping = () =>{
    if(this.state.submit.opponent === 'operator')
      SocketActions.sendUserNotTyping()
  }

  render(){
    return (
      <div className="inputBar">
        <TypingStatus {...this.state.typing}/>
        <div className="inputBar__inner">
          <UploadButton {...this.state.upload} sessionId={this.state.session} caseId={this.state.case} message={this.state.chatMessage}/>
          <InputField {...this.state.input} onChange={this.onChange} onTyping={this.onTyping} onNotTyping={this.onNotTyping} onKeyPress={this.onKeyPress}/>
          <SubmitButton onClick={this.submit} grayOut={this.state.submit.grayOut}/>
        </div>
      </div>)
  }
}

export default Container.create(InputContainer)