import React, { Component } from "react"
import { Container } from "flux/utils"
import i18n from "../i18n"

import ConnectionStore from "../stores/ConnectionStore";
import QueueStore from "../stores/QueueStore";
import SkillStore from "../stores/SkillStore";
import CaseStore from "../stores/CaseStore";
import StatusStore from "../stores/StatusStore";

class StatusBarContainer extends Component {
  static getStores() {
    return [
      ConnectionStore,
      QueueStore,
      SkillStore,
      CaseStore,
      StatusStore,
    ]
  }
  static calculateState(prevState) {
    return {
      connection: ConnectionStore.getState(),
      status: StatusStore.getState(),
      queue: QueueStore.getState(),
      skill: SkillStore.getState(),
      case: CaseStore.getState(),
    }
  }

  render() {
    const {classification, text, data='',} = this.state.status
    const caseId = this.state.case
    const skill = this.state.skill
    const queue = this.state.queue
    ? (caseId && skill)
      ?this.state.queue.indexOf(`${skill}:${caseId}`)
      :this.state.queue.length
    : " − "
    const translatedText = (text) ? i18n.t(text,{data,queue}) : []
    return (
    <div className={`notificationBar ${classification}`}>
      <div className={`notificationBar__inner ${classification}`}>
        <span className={`notificationBar__text ${classification}`}>{translatedText.map((text,index) => <p key={index}>{text}</p>)}</span>
        <button className={`notificationBar__btn ${classification}`}>{i18n.t('statusMessage.reconnect')}</button>
      </div>
    </div>
    )
  }
}

export default Container.create(StatusBarContainer)
