import React from "react";

export default ({ type, id, value="", required, maxLength, placeholder, onChange, onBlur, isSpace }) => (
  <input
    id={id}
    value={value}
    type={type}
    required={required}
    className={`${id} modalInput__field ${isSpace ? 'modalInput__space' : ''}`}
    placeholder={placeholder}
    maxLength={maxLength}
    onChange={onChange}
    onBlur={onBlur}
  />
)
