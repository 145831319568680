import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/UserInfoActions"

class UserInfoStore extends ReduceStore {

  getInitialState() {
    return {}
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.set:
        const { item, value } = action
        return  { ...state, [item]:value }
      default:
        return state
    }
  }
}

export default new UserInfoStore(Dispatcher)
