import React from "react";

export default ({ id, value, required, maxLength, placeholder, onChange, onBlur, isSpace, additionalMessage }) => (
  <>
    {
      additionalMessage
        ? <p className="modalInput__textArea__additionalMessage">
            {additionalMessage}
          </p>
        : null
    }
    <textarea
      id={id}
      value={value}
      required={required}
      className={`${id} modalInput__field ${isSpace ? 'modalInput__space' : ''} modalInput__textArea`}
      placeholder={placeholder}
      maxLength={maxLength}
      onChange={onChange}
      onBlur={onBlur}
    />
  </>
)
