import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/CaseActions"

class CaseStore extends ReduceStore {
  getInitialState() {
    return ""
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.initialize:
        return ""
      case keys.set:
        return action.caseId
      default:
        return state
    }
  }
}

export default new CaseStore(Dispatcher)
