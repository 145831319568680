import React, { Component } from "react"
import { Container } from "flux/utils"
import { debounce } from 'lodash'
import { ToastContainer, toast } from "react-toastify"
import { isSmartPhone } from "chat-common"
import i18n from "../i18n"
import objectFitImages from 'object-fit-images';

//Containers
import MenuContainer from './MenuContainer'
import MessageContainer from './MessageContainer'
import InputContainer from './InputContainer'
import ModalWindowContainer from "./ModalWindowContainer"
import StatusBarContainer from "./StatusBarContainer"
import HumanChatStatusBarContainer from './HumanChatStatusBarContainer'
//Components
import Announcement from "../components/Announcement"
import DropArea from "../components/DropArea"
import Notification from "../components/Notification"
import Suggestions from "../components/Suggestions"
import ScrollBtn from "../components/ScrollBtn"
//Stores
import AnnouncementStore from "../stores/AnnouncementStore"
import CaseStore from "../stores/CaseStore"
import ConnectionStore from "../stores/ConnectionStore"
import ConversationStore from "../stores/ConversationStore"
import ChatSettingStore from "../stores/ChatSettingStore"
import ChatMessageStore from "../stores/ChatMessageStore"
import DropAreaStore from "../stores/DropAreaStore"
import MessageStore from '../stores/MessageStore'
import NotificationStore from "../stores/NotificationStore"
import ScrollStore from "../stores/ScrollStore"
import SSOStore from "../stores/SSOStore"
import SubmitStore from "../stores/SubmitStore"
import SuggestionStore from "../stores/SuggestionStore"
import TitleStore from "../stores/TitleStore"
import QuestionnaireStore from "../stores/QuestionnaireStore"
import ShopStore from "../stores/ShopStore"
import SessionStore from "../stores/SessionStore"
import OrderStore from "../stores/OrderStore"
import QueueStore from "../stores/QueueStore"
import DisplayQueueCategoryStore from '../stores/DisplayQueueCategoryStore';
import HumanChatStatusStore from "../stores/HumanChatStatusStore"

//Actions
import DragAreaActions from "../actions/DropAreaActions"
import MenuActions from "../actions/MenuActions"
import NotificationActions from "../actions/NotificationActions"
import { keys as connectionKeys} from "../actions/ConnectionActions"
import { keys as conversationKeys} from "../actions/ConversationActions"
import { keys as humanChatStatusKeys } from "../actions/HumanChatStatusActions"
import SocketActions from "../actions/SocketActions"
import ScrollActions from "../actions/ScrollActions"
import SubmitActions from "../actions/SubmitActions";
import SuggestionActions from "../actions/SuggestionActions"
import InputActions from "../actions/InputActions";
import ConversationActions from "../actions/ConversationActions";
import ShopActions from "../actions/ShopActions";
import OrderActions from "../actions/OrderActions";
import SkillStore from "../stores/SkillStore"

class App extends Component {
  static getStores() {
    return [
      AnnouncementStore,
      CaseStore,
      ConversationStore,
      DropAreaStore,
      MessageStore,
      ChatMessageStore,
      NotificationStore,
      SkillStore,
      SubmitStore,
      SuggestionStore,
      ConnectionStore,
      ChatSettingStore,
      SSOStore,
      ScrollStore,
      TitleStore,
      QuestionnaireStore,
      ShopStore,
      SessionStore,
      OrderStore,
      QueueStore,
      DisplayQueueCategoryStore,
      HumanChatStatusStore,
    ]
  }

  static calculateState(prevState) {
    return {
      announcement: AnnouncementStore.getState(),
      conversation: ConversationStore.getState(),
      droparea: DropAreaStore.getState(),
      message: MessageStore.getState(),
      chatMessage: ChatMessageStore.getState(),
      notification: NotificationStore.getState(),
      skill: SkillStore.getState(),
      submit: SubmitStore.getState(),
      suggestions: SuggestionStore.getState(),
      connection: ConnectionStore.getState(),
      chatSetting: ChatSettingStore.getState(),
      sso: SSOStore.getState(),
      scroll: ScrollStore.getState(),
      title: TitleStore.getState(),
      questionnaire: QuestionnaireStore.getState(),
      shop: ShopStore.getState(),
      order: OrderStore.getState(),
      queue: QueueStore.getState(),
      session: SessionStore.getState(),
      case: CaseStore.getState(),
      humanChatStatus: HumanChatStatusStore.getState(),
    }
  }

  componentDidMount(){
    objectFitImages();
    document.addEventListener('click',(e) => {
      if(e.target.localName === 'a'){
        SocketActions.linkOpened({targetLink: e.target.href})
      }
    })
    const lang = this.getLangParam()
    i18n.changeLanguage(lang)
    NotificationActions.setLang(lang)
    var event;
    if (typeof(window.Event) === 'function') {
      event = new Event('SINGLE_PAGE_APPLICATION_LOAD');
      document.dispatchEvent(event);
    } else {
      try {
        event = document.createEvent('Event');
        event.initEvent('SINGLE_PAGE_APPLICATION_LOAD', true, true);
        document.dispatchEvent(event);
      } catch (e) {}
    }
    document.title = i18n.t(this.state.title)
  }

  componentDidUpdate(prevProps, prevState){
    //const ichibaChatReferrerId = this.props.match.params.ichiba_chat_referrer_id
    if(
    ( this.state.conversation.type !== conversationKeys.start
    && !this.state.chatSetting.chatSessionId)
    || (prevState.connection.type !== this.state.connection.type
    && prevState.connection.type !== connectionKeys.connect
    && this.state.connection.type === connectionKeys.connect)){
      this.handleConnect(prevState)
    }
    if(this.state.announcement.title !== prevState.announcement.title) toast(<Announcement {...this.state.announcement}/>, { position: "bottom-right", className: "announcement-toast" })
    //if(prevState.message !== this.state.message) this.scrollToBottom()
  }

  handleConnect = debounce((prevState)=>{
    const { conversationCase='', shopId='', orderNumber='' } = this.props.match.params || {};
    const healthChecker = (this.props.match.params.healthChecker === "true") ? this.props.match.params.healthChecker : "";
    const sessionId = this.state.chatSetting.chatSessionId
    const {opponent} = this.state.submit
    const { type: humanChatStatusType } = this.state.humanChatStatus
    const isInputEnabled = (humanChatStatusType === humanChatStatusKeys.initialize || humanChatStatusType === humanChatStatusKeys.isConnected)
    const lang = this.getLangParam()
    const location = window.location;
    const facilityNumber = new URLSearchParams(location.search).get('spn');
    SocketActions.startBot(opponent, conversationCase, 'asdf', shopId, orderNumber, sessionId, healthChecker, location.href, location.pathname, document.referrer, this.state.sso.id, lang, facilityNumber, isInputEnabled)
    ConversationActions.start();
    ShopActions.load(shopId);
    OrderActions.load(orderNumber);
  },1000)

  getLangParam = () => {
    const resourcesLang = Object.keys(i18n.options.resources)
    const lang = new URLSearchParams(window.location.search).get('lang')
    return (lang && resourcesLang.indexOf(lang) >= 0) ? lang : 'ja'
  }

  scrollToBottom = () => {
    const { contents=[] } = this.state.message
    const balloonId = (contents.length)?`.${contents[contents.length - 1].balloonId}` : undefined;
    ScrollActions.scrollDown(balloonId)
  }

  onCarouselPrev = () => {
    SuggestionActions.prev()
  }
  onCarouselNext = () => {
    SuggestionActions.next()
  }

  clickSuggestion = (event) => {
    if(this.state.submit.opponent==='bot'){
      const watermark = this.state.message.length
      const timing = (watermark === 0)?'exception':this.state.message.timing;
      SocketActions.report({timing, place:'suggestion', text: event.target.value, action:'post-message'})
    }
    SubmitActions.submit(event.target.value, 'suggestion', this.state.submit, this.state.message.contents, this.state.questionnaire)
  }

  scrollUpdate = debounce(ScrollActions.scroll,100)

  clickMessageArea = () => {
    if(!isSmartPhone(navigator.userAgent)){
      InputActions.focus()
    }
    MenuActions.close()
  }
  clickMessageArea = () => {
    MenuActions.close()
  }
  clickMenuArea = () => {
    if(!isSmartPhone(navigator.userAgent)){
      InputActions.focus()
    }
  }
  onDragEnter = (e) => {
    DragAreaActions.show()
  }
  onClickNotification = () => {
    SocketActions.report({timing:'', place:'notification', text:'', action:'click'});
    window.focus();
  }
  rootClass = (isSmartPhone(navigator.userAgent))?'sp':'pc';
  render() {
    return (
    <div className={this.rootClass} onClick={this.clickMenuArea} onDragEnter={this.onDragEnter}>
      <MenuContainer />
      <div className="u-clearfix" onClick={this.clickMessageArea}>
        <StatusBarContainer/>
        <HumanChatStatusBarContainer />
        <MessageContainer />
        <DropArea {...this.state.droparea} message={this.state.chatMessage} sessionId={this.state.session} caseId={this.state.case}/>
        <Suggestions {...this.state.suggestions} clickSuggestion={this.clickSuggestion} onCarouselPrev={this.onCarouselPrev} onCarouselNext={this.onCarouselNext} />
        <InputContainer />
        <ScrollBtn {...this.state.scroll} scroll={this.scrollUpdate} onClick={this.scrollToBottom}/>
        <Notification {...this.state.notification} onClick={this.onClickNotification}/>
        <ToastContainer type="default" autoClose={180000} hideProgressBar={true} newestOnTop={true} closeOnClick={false} pauseOnHover={false}
        className="announcement-toast-container" bodyClassName="announcement-toast-detail" style={{bottom: "65px", zIndex: 0}} />
        <ModalWindowContainer />
      </div>
  </div>)
  }
}

export default Container.create(App)
