import React from "react"
import MagicDropzone from 'react-magic-dropzone'
import DropAreaActions from '../actions/DropAreaActions'
import SocketActions from "../actions/SocketActions";
import i18n from "../i18n"
export default ({ visible, disabled, descriptions, src, message, sessionId, caseId }) => {
  const onDrop = (accepted) => {
    DropAreaActions.hide()
    const file = accepted[0];
    const { name:filename, type:filetype, size:filesize } = file
    const id = parseInt(new Date() / 1000, 10)
    if (filesize > 26214400) {
      return SocketActions.uploadFileSizeOver(id)
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      // if (file.type.startsWith("image")) {
      //    MessageActions.addUserMessage(file.name, event.target.result, true, false, id)
      // } else {
      //    MessageActions.addUserMessage(file.name, fileimg, true, true, id)
      // }
      const messages = message.contents || []
      const lastFrom = messages.length && messages[messages.length - 1].from
      SocketActions.uploadFile(filename, filetype, filesize, event.target.result, id, sessionId, caseId, lastFrom)
    };
    //reader.readAsDataURL(file);
    reader.readAsArrayBuffer(file)
  }

  const onDragLeave = () => {
    DropAreaActions.hide()
  }
  const transDescriptions = i18n.t(descriptions)
  return (
    (disabled || !visible)
      ? (<div></div>)
      : (<div className="file-drop" onDragLeave={onDragLeave}>
        <MagicDropzone onDrop={onDrop} className="file-drop-target file-drop-dragging-over-frame">
          <img src={src} height="128px" alt="" /><br />
          {transDescriptions.map((description, index) =>
            <p>{description}</p>
          )}
          <button onClick={onDragLeave}>ファイルドロップを終了するⓍ</button>
        </MagicDropzone>
      </div>)
  )
}
