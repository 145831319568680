import React from "react"
import OrderCarousel from "./OrderCarousel"
import CustomReactMarkdown from "./CustomReactMarkdown";

export default ({ text, time, icon, radius, bg, attachments, disabled, watermark, device='pc', inlineStyle, clickButton, clickLink, balloonId, scrollToBottom }) => {
  React.useEffect(() => scrollToBottom(`.${balloonId}`),[])

  return (
  <div style={{ height: "100%" }}>
    <div className="operatorMessage u-clearfix">
      <div className="operatorMessage__inner">
        <div className="operatorMessage__grid">
          <div className="operatorMessage__icon" style={{borderRadius:radius, background:bg}}>
            <img src={icon} alt={""} style={{width:"100%"}}/>
          </div>
        </div>
        <div className="operatorMessage__grid operatorMessage__grid--balloon">
          <div className="operatorMessage__balloon"><CustomReactMarkdown children={text} /></div>
        </div>
        <div className="operatorMessage__grid operatorMessage__grid--time">
          <div className="operatorMessage__time">{time}</div>
        </div>
      </div>
    </div>
    <div className="operatorMessage__plain">
      <OrderCarousel attachments={attachments} disabled={disabled} watermark={watermark}
          device={device} inlineStyle={inlineStyle} clickButton={clickButton} clickLink={clickLink} />
    </div>
  </div>
)}