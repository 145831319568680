import Dispatcher from "../Dispatcher"
import SocketActions from "./SocketActions"

export const keys = {
  show: Symbol("modal:show"),
  close: Symbol("modal:close"),
  submit: Symbol("modal:submit"),
}

export default {
  show() {
    Dispatcher.dispatch({
      type: keys.show,
      isOpen: true,
    })
  },
  close() {
    Dispatcher.dispatch({
      type: keys.close,
      isOpen: false,
    })
  },
  submit(opponent, info, skill, contents, questionnaire) {
    Dispatcher.dispatch({
      type: keys.submit,
      isOpen: false,
    })
    SocketActions.postDetail({opponent, info, skill, contents, questionnaire, isCancel:false});
  },
}
