import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/DisconnectActions"
import { settings } from "../config/settings"

class DisconnectStore extends ReduceStore {

  getInitialState() {
    return settings.disconnect
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.initialize:
        return action
      case keys.show:
      case keys.hide:
        return {
          ...state,
          isDisplay: action.isDisplay
        }
      case keys.disable:
      case keys.enable:
          return {
            ...state,
            disabled: action.disabled
          }
      case keys.useDisconnect:
        return {
          ...state,
          useDisconnect: true
        }
      default:
        return state
    }
  }
}

export default new DisconnectStore(Dispatcher)
