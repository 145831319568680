import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/FormInputActions"
import { settings } from "../config/settings"

class FormInputStore extends ReduceStore {

  getInitialState() {
    return settings.formInput
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.set:
        const { itemset } = action
        return { ...state, ...itemset }
      case keys.clear:
        return { orderNumber: action.orderNumber }
      case keys.change:
        const { item, value } = action
        return  {...state, [item]:value }
      default:
        return state
    }
  }
}

export default new FormInputStore(Dispatcher)
