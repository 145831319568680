import Dispatcher from "../Dispatcher"

export const keys = {
  set: Symbol("queue:set"),
}

export default {
  set(queueState){
    Dispatcher.dispatch({
      type: keys.set,
      queueState,
    })
  },
}