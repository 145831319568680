import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/ChatMessageActions"
import { settings } from "../config/settings"

class ChatMessageStore extends ReduceStore {

  getInitialState() {
    return settings.chatMessage
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.isSP:
        return { 
          ...state, 
          device: action.device,
        }
      case keys.completedUpload:
        const { id } = action
        for (let i = state.contents.length - 1; i >= 0; i--) {
          if (state.contents[i].id === id){
            state.contents[i].isSending=false;
          break;}
        }
        return {...state}
      case keys.updateMessages:{
        const contents =  action.messages.map((message,index) => {
          const {contents, createTime, from } = message
          const { text, contentType, contentUrl } = contents
          return { now: createTime.toDate(), text, from, contentType, contentUrl, balloonId: `chat_balloon_${index}` }
        })
        return {...state, contents}
      }
      case keys.sendingFile:{
        const { fileinfo, isImage } = action
        const { filename, data_uri } = fileinfo
        const { contents } = state
        const nextContents = contents.concat({text:filename, from: 'user', contentUrl:data_uri, isSending:true, isImage})
        return {...state, contents: nextContents }
      }
      default:
        return state
    }
  }
}

export default new ChatMessageStore(Dispatcher)
