import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/MenuActions"
import { settings } from "../config/settings"

class MenuStore extends ReduceStore {

  getInitialState() {
    return settings.menu
  }

  reduce(state, action) {
    let { menus } = state
    switch (action.type) {
      case keys.initialize:
        menus = menus
        .sort((menu, comparisonMenu) => action.ids.indexOf(menu.id) - action.ids.indexOf(comparisonMenu.id))
        .map(menu => {
          menu.isDisplay = action.ids.includes(menu.id);
          return menu
        })
        return {
          ...state,
          menus
        }
      case keys.open:
      case keys.close:
        return {
          ...state,
          isActive: action.isActive,
        }
      case keys.toggle:
        return { ...state, isActive: (state.isActive) ? "" : 'is-active' }
      case keys.show:
      case keys.hide:
        return {
          ...state,
          isDisplay: action.isDisplay,
        }
      case keys.disable:
      case keys.enable:
        return {
          ...state,
          disabled: action.disabled,
        }
      case keys.updateOperator:
        const operatorMenuIndex = menus.findIndex(x => x.id === 'operator')
        menus[operatorMenuIndex].isDisplay = action.isDisplay
        return {
          ...state,
          menus
        }      
      default:
        return state
    }
  }
}

export default new MenuStore(Dispatcher)
