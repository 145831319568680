import 'core-js/stable';
import 'react-app-polyfill/ie11';
import React from "react"
import SquareBtnBordered from "./SquareBtnBordered"
import SquareBtnBorderedArrow from "./SquareBtnBorderedArrow"
import { createInquiryUrl } from "chat-common"
import i18n from "../i18n"


export default ({ isDisplay, device, messages, options = [], shop={}, order={}, clickButton, clickLink }) => {
  const translatedMessages = i18n.t(messages)
  const lang = i18n.language
  const arrayMessage = (translatedMessages instanceof Array) ? translatedMessages : []

  const { shopId='' } = shop;
  const { orderNumber='' } = order;
  return (isDisplay) ?
    (<div className="chatBotError">
      <div className="chatBotError__text">
        {arrayMessage.map((message,index) => (
          <p key={index}>{message}</p>
        ))}
      </div>
      {options.map((option,index) => {
        const translatedTitle = i18n.t(option.titles)
        const arrayTitle = (translatedTitle instanceof Array) ? translatedTitle : []
        const joinedTitle = arrayTitle.join('<br>')
        switch (option.btntype) {
          case 'submit':
            return <SquareBtnBordered
            text={joinedTitle}
            clickButton={clickButton} value={i18n.t(option.submit)} key={index}/>
          case 'openLink':
            return <SquareBtnBorderedArrow
              text={joinedTitle}
              href={(option.formLink) ? createInquiryUrl({ shopId: shopId || '', orderNumber: orderNumber || '' }) : option.link[lang][device]}
              clickLink={clickLink} key={index}/>
          default:
            return <div key={index}></div>
        }
      })}
    </div>)
    : (<div></div>)
}
