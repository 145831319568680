import React from "react"
import CustomReactMarkdown from "./CustomReactMarkdown";
import ChoiceQuestion from "./ChoiceQuestion"
import i18n from "../i18n"

export default ({isStart, icon, radius, bg, time, images=[], buttons, text, disabled, availability, clickButton, clickLink, balloonId, scrollToBottom }) => {
  const wordNormalClass = (i18n.language === 'en') ? 'messageWordNormal' : ''
  React.useEffect(() => {
    if(isStart) scrollToBottom(`.${balloonId}`)
  },[])

  return (
    <div className={`operatorMessage u-clearfix`}>
      <div className="operatorMessage__inner">
        <div className="operatorMessage__grid">
          <div className="operatorMessage__icon" style={{borderRadius:radius, background:bg}}>
            <img src={icon} alt={""} style={{width:"100%"}}/>
          </div>
        </div>
        <div className="operatorMessage__grid operatorMessage__grid--balloon">
          <div className={`operatorMessage__balloon ${wordNormalClass}`}>
            {images.map((image, index) => 
              <img alt={""} key={index} style={{ width: "100%" }} src={image.url} />
            )}
            <CustomReactMarkdown children={text}/>
            <ChoiceQuestion buttons={buttons} clickButton={clickButton} clickLink={clickLink} disabled={disabled} availability={availability}/>
          </div>
        </div>
        <div className="operatorMessage__grid operatorMessage__grid--time">
          <div className="operatorMessage__time">{time}</div>
        </div>
      </div>
    </div>
)}
