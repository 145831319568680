import React from "react"
import OrderCarouselCard from "./OrderCarouselCard"
import smoothscroll from 'smoothscroll-polyfill';

export default ({ attachments, disabled, watermark, device='pc', inlineStyle, clickButton, clickLink }) => {

  const onCarouselNext = () => {
    const carouselDiv = document.getElementById("orderCarousel__" + watermark)
    const cardWidth = 265 + 10;
    const scale = Math.floor((carouselDiv.clientWidth + 20) / cardWidth);
    if ('scroll-behavior' in carouselDiv.style)
      carouselDiv.scrollLeft += cardWidth * scale;
    else {
      smoothscroll.polyfill();
      const scPos = carouselDiv.scrollLeft + cardWidth * scale;
      carouselDiv.scroll({top: 0, left: scPos, behavior: 'smooth'});
    }
  }

  const onCarouselPrev = () => {
    const carouselDiv = document.getElementById("orderCarousel__" + watermark)
    const cardWidth = 265 + 10;
    const scale = Math.floor((carouselDiv.clientWidth + 20) / cardWidth);
    if ('scroll-behavior' in carouselDiv.style)
      carouselDiv.scrollLeft -= cardWidth * scale;
    else {
      smoothscroll.polyfill();
      const scPos = carouselDiv.scrollLeft - cardWidth * scale;
      carouselDiv.scroll({top: 0, left: scPos, behavior: 'smooth'});
    }
  }

  return (
    <div className="orderCarousel" id={"orderCarousel__" + watermark}>
      <div className={`orderCarousel__navWrap orderCarousel__navWrap--prev orderCarousel__navWrap_${device}`}>
        <button className="orderCarousel__nav orderCarousel__nav--prev" onClick={onCarouselPrev}>PREV</button>
      </div>
      <div className={`orderCarousel__navWrap orderCarousel__navWrap--next orderCarousel__navWrap_${device}`}>
        <button className="orderCarousel__nav orderCarousel__nav--next" onClick={onCarouselNext}>NEXT</button>
      </div>
      <ul className="orderCarousel__attachments" id={"orderCarousel__attachments__" + watermark} style={{ transform: `translateX(0)` }} >
        {attachments.map((attachment, index) =>
          <OrderCarouselCard
            images={attachment.content.images}
            buttons={attachment.content.buttons}
            title={attachment.content.title}
            subtitle={attachment.content.subtitle}
            text={attachment.content.text}
            disabled={disabled}
            clickButton={clickButton}
            clickLink={clickLink}
            key={index}
          />)}
      </ul>
    </div>
  )
}

