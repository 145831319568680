import Dispatcher from "../Dispatcher"

export const keys = {
  isSP: Symbol("chat-message:is-sp"),
  completedUpload: Symbol('chat-message:completed-upload'),
  updateMessages: Symbol('chat-message:update-messages'),
  sendingFile: Symbol('chat-message:sending-file'),
}

export default {
  isSP(isSP){
    Dispatcher.dispatch({
      type: keys.isSP,
      device: (isSP)?'sp':'pc',
    })
  },
  completedUpload(id){
    Dispatcher.dispatch({
      type: keys.completedUpload,
      id,
    })
  },
  updateMessages(messages){
    Dispatcher.dispatch({
      type: keys.updateMessages,
      messages
    })
  },
  sendingFile(fileinfo,isImage){
    Dispatcher.dispatch({
      type: keys.sendingFile,
      fileinfo,
      isImage,
    })
  }
}