import Dispatcher from "../Dispatcher"

export const keys = {
  initialize: Symbol("staff:initialize"),
  set: Symbol("staff:set"),
  clear: Symbol("staff:clear"),
}

export default {
  initialize(){
    Dispatcher.dispatch({
      type: keys.initialize
    })
  },
  set(staffId){
    Dispatcher.dispatch({
      type: keys.set,
      staffId,
    })
  },
  clear(staffId){
    Dispatcher.dispatch({
      type: keys.clear,
    })
  },
}