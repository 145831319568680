const resources = {
  ja: {
    translation: {
      title: '楽天市場お問い合わせチャット',
      announcementTitle: 'お知らせ',
      botError: {
        message: [
          'メンテナンス中のため、現在自動応答サービスがご利用いただけません。',
          '恐れ入りますが、以下の方法でお問い合わせいただくか、今しばらく時間をおいてから再度アクセスください。'
        ],
        options: {
          operator: {
            titles: ['このままオペレーターとチャットする','24時間 年中無休'],
            submit: 'オペレーターとチャットしたい'
          },
          mail: {
            titles: ['メールでお問い合わせ']
          },
          rgroup: {
            titles: ['楽天グループサービスへの問い合わせ']
          }
        }
      },
      cancelButton: '戻る',
      cancelOperatorMessage: 'オペレーターとのチャットをやめる',
      disconnectButton: {
        main: '切断', span: 'する'
      },
      fileDrop: ['こちらのエリアに','ファイルをドロップしてください。'],
      menus: {
        operator: {
          name: ['オペレーターとチャット','現在：{{queue}}名{{more}}お待ちです。'],
          submit: 'オペレーターとチャットしたい'
        },
        tel: {
          name: ['電話でお問い合わせ'],
          submit: '電話でお問い合わせしたい'
        },
        mail: {
          name: ['メールでお問い合わせ'],
          submit: 'メールでお問い合わせしたい'
        },
        rgroup: {
          name: ['楽天グループサービスへ','のお問い合わせ']
        },
        x: {
          name: ['各種情報発信中！','楽天市場SNSサポート']
        },
        reset: {
          name: ['最初から質問しなおす'],
          submit: '最初から質問しなおす'
        }
      },
      modal: {
        errorMessage: {
          required: 'この項目は入力必須です。',
          format: 'フォーマットが不正です。',
        },
        form: {
          email: {
            name: 'メールアドレス',
          },
          name: {
            name: '氏名',
            placeholder: '例)楽天太郎',
          },
          lastName: {
            name: '姓',
            placeholder: '例)楽天',
          },
          firstName: {
            name: '名',
            placeholder: '例)太郎',
          },
          kana: {
            name: 'フリガナ',
            placeholder: '例)ラクテンタロウ',
          },
          tel: {
            name: '電話番号(ハイフン不要です)',
            placeholder: '例)08012345678'
          },
          birthDay: {
            name: '生年月日',
            placeholder: '例)19990110',
          },
          postalCode: {
            name: '郵便番号',
            placeholder: '例)1010001',
          },
          orderNumber: {
            name: '受注番号',
          },
          address: {
            name: '住所',
          },
          inquiryContent: {
            name: 'お問い合わせ内容(100文字以内で簡単に内容をご説明ください)',
            placeholder: '例)注文した商品のキャンセル方法がわからず困っています。誤って注文してしまったのでキャンセルしたいです。',
          },
          birthDayOld: {
            name: '誤っている生年月日(西暦)',
            placeholder: '例)19990101'
          },
          birthDayNew: {
            name: '正しい生年月日(西暦)',
            placeholder: '例)19990110'
          },
          birthDayChangeReason: {
            name: '変更理由'
          },
          confirmChangeBirthDay: {
            name: '過去にお問い合わせで生年月日変更をしたことはない'
          },
          confirmShareInfo: {
            name: 'お問い合わせ内容によっては、楽天市場の各グループ会社/出店店舗へお客様の情報を共有します。場合により共有先からのご連絡となりますことをご了承ください。'
          }
        },
        message:{
        	defaultMessage: [
          	'オペレーターがスムーズに対応させていただくために、お手数をおかけしますが、以下に入力をお願いします。',
          	'お問い合わせ内容によっては、ご入力いただいた情報をご利用店舗にお伝えする場合がございますので、ご了承ください。'
        	],
			    messageWithoutShareInfo: [
		 		    'お客様のご登録状況やご注文状況を確認してオペレーターが対応いたします。',
        		'スムーズに対応させていただくためにも、お客様にはお手数おかけしますが以下のフォームに入力をお願いいたします。'
			    ],
          forChangeBirthdayWithLogin: [
            'ログインありがとうございます。会員情報の内容にそって対応させていただきます。お問い合わせ内容の入力をお願いします。'
          ]
        },
        waitcount: '現在{{queue}}名{{more}}お待ちいただいています。',
        purchaseHistoryNotExisted: '３ヶ月以内の受注はありません',
        textareaAdditionalMessage: {
          noPurchaseHistory: '下記のお問い合わせ内容詳細欄に受注番号も合わせてお知らせください。'
        },
        selectItems: {
          birthDayChangeReason: [
            '楽天モバイルの契約のため',
            '楽天証券を利用するため',
            '楽天銀行を利用するため',
            'その他',
          ],
          loginUserInquiryCategory: [
            '楽天会員登録/ログイン',
            'ポイント/キャンペーン',
            'お買い物に関するトラブル',
            '決済',
            'その他',
          ],
          birthDayChangeCategory: [
            '生年月日変更'
          ]
        },
        checkBoxContent: {
          confirmChangeBirthDay: '確認しました',
          disclaimer: '了承しました',
        }
      },
      seeMore: 'もっと見る',
      sendButton: '送信',
      humanChatStatusMessage: {
        connectingOperator: ['対応可能なオペレーターを検索中です','現在：{{queue}}名{{more}}お待ちです。'],
        operator: ['オペレーターがチャットを開始しました'],
        disconnectOperator: ['オペレーターがチャットを終了しました'],
        disconnectUser: ['チャットの終了を受け付けました'],
        timeoutOperator: ['チャットのセッションが切れました'],
      },
      statusMessage: {
        connect: ['接続'],
        reconnect: ['再接続'],
        connectingOperator: ['対応可能なオペレーターを検索中です','現在：{{queue}}名{{more}}お待ちです。'],
        transferOperator: ['別のオペレーターに転送中です'],
        operator: ['オペレーターがチャットを開始しました'],
        disconnectOperator: ['オペレーターがチャットを終了しました'],
        disconnectUser: ['チャットの終了を受け付けました'],
        timeoutOperator: ['チャットのセッションが切れました'],
        disconnect: ['切断'],
        connectError: ['接続エラー'],
        reconnectError: ['再接続エラー'],
        reconnectFailed: ['再接続失敗'],
        tryReconnect: ['再接続試行'],
        reconnecting: ['再接続中({{data}}回目)']
      },
      typingMessage: 'がタイピング中',
      placeholder: {
        bot: 'お問い合わせ内容を入力してください。例)住所を変更したい',
        inputDisable: '表示されているボタンの中から選択してください。',
        selectCategory: 'カテゴリをボタンから選択してください。',
        inputEmail: 'こちらにメールアドレスを入力してください。',
        connectingOperator: 'オペレーターにお繋ぎします。しばらくお待ちください。',
        transferOperator: '別のオペレーターにお繋ぎします。しばらくお待ちください。',
        operator: 'メッセージを入力してください。',
        disconnect: 'ご利用ありがとうございました。\nチャットを行いたい場合は新たにチャットウィンドウを開いてください。',
        operatorError: 'お問い合わせ内容を入力してください。例)住所を変更したい'
      },
      profileName: {
        bot: ['楽天市場','お客様サポートセンター'],
        connectingOperator: ['検索中'],
        transferOperator: ['転送中'],
        operator: '担当',
        disconnect: ['接続を切断しました。']
      },
      questionnaireMessage: "後ほど弊社サポートに対するアンケートをメールまたはSMSで送る場合がございます。\nご回答いただけると幸いです。"
    }
  }
}

module.exports = {
  resources
}