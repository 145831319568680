import React, { useEffect } from 'react';
import CustomReactMarkdown from "./CustomReactMarkdown";

import i18n from "../i18n";
import { settings } from "../config/settings";
import { changeToCustomHTML, isSmartPhone } from "chat-common";

import FormInputField from "./FormInputField"
import FormSubmitButton from "./FormSubmitButton"
import FormCancelButton from "./FormCancelButton"

import FormInputActions from "../actions/FormInputActions"


const { language: lang } = i18n;
const modalIcon = settings.modalWindow.icon.logoSrc[lang];


const Form = ({ userInfo, modalMessage, formFormat, formInput, formErrMsg, queue, onCloseModal, onSubmitModal, skill }) => {
  useEffect(()=>{
    setTimeout(()=>{FormInputActions.set(userInfo)})
  },[])

  const disabled = formFormat.find( info => !info.isValid ) ? true : false
  const rootClass = (isSmartPhone(navigator.userAgent))?'sp':'pc';
  const translatedMessages = i18n.t(`modal.message.${modalMessage}`);
  const arrayMessage = (translatedMessages instanceof Array) ? translatedMessages : [];
  const checkedArrayMsg = arrayMessage.map((message) => changeToCustomHTML(message));
  const messageComponent = checkedArrayMsg.length > 0 ? (
    <>
      <div style={{float: 'left'}}>
        <div className={'operatorMessage__grid'}>
          <div className={'operatorMessage__icon'}><img src={modalIcon} alt="icon" style={{width: '100%'}}/></div>
        </div>
      </div>
      <div className={'operatorMessage__balloon'} style={{fontSize: 'smaller',display: 'table-cell'}}>
        {checkedArrayMsg.map((message,index) => (<CustomReactMarkdown key={index} children={message}/>))}
      </div>
    </>
  ) : null

  return (
    <div className={rootClass}>
      {messageComponent}
      <div>
        <div>
        {
          formFormat.map((formInfo, index) => (
            <FormInputField
              formInfo={{
                ...formInfo,
                defaultValue: userInfo[formInfo.id],
                value: formInput[formInfo.id],
                errMsg: formErrMsg[formInfo.id],
                skill
              }}
              key={index}
            />
          ))
        }
        </div>
        <p>{i18n.t('modal.waitcount',{queue})}</p>
        <hr></hr>
        <div style={{float: 'right', paddingBottom: '20px'}}>
          <FormCancelButton onCloseModal={onCloseModal} />
          <FormSubmitButton disabled={disabled} onSubmitModal={onSubmitModal} />
        </div>
      </div>
    </div>
  )
}

export default Form;
