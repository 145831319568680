import 'core-js/stable';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import App from './containers/App';
import Auth from './containers/Auth';
import './index.css';
import './i18n';
import 'url-search-params-polyfill';
import axios from 'axios'
import Cookies from 'js-cookie';

function shopMerchant() {
  axios.get('/login')
    .then(res => {
      const { shopId, id } = res.data
      if (shopId && id) {
        document.getElementById('ratShopId').value = shopId
        ReactDOM.render(
          <BrowserRouter>
            <Auth id={id}>
              <div className="router">
                <Switch>
                  <Route exact path="/" component={App} />
                  <Route exact path="/:conversationCase" component={App} />
                </Switch>
              </div>
            </Auth>
          </BrowserRouter>,
          document.getElementById('root')
        );
      } else {
        window.location.assign('https://mainmenu.rms.rakuten.co.jp/?act=app_login_error')
      }
    }).catch(e => {
      window.location.assign('https://mainmenu.rms.rakuten.co.jp/?act=app_login_error')
    })
  return
}

function shopMerchantInternal() {
  ReactDOM.render(
    <BrowserRouter>
      <div className="router">
        <Switch>
          <Route exact path="/" component={App} />
          <Route exact path="/:conversationCase" component={App} />
        </Switch>
      </div>
    </BrowserRouter>,
    document.getElementById('root')
  );
}

function showFirstParty() {
  ReactDOM.render(
    <BrowserRouter>
      <div className="router">
        <Switch>
          <Route exact path="/shopId/:shopId" component={App}/>
          <Route exact path="/orderNumber/:orderNumber" component={App}/>
          <Route exact path="/shopId/:shopId/:conversationCase" component={App}/>
          <Route exact path="/orderNumber/:orderNumber/:conversationCase" component={App}/>
          <Route exact path="/shopId/:shopId/health_checker/:healthChecker" component={App}/>
          <Route exact path="/orderNumber/:orderNumber/health_checker/:healthChecker" component={App}/>
        </Switch>
      </div>
    </BrowserRouter>,
    document.getElementById('root')
  );
}

function showDefault() {
  ReactDOM.render(
    <BrowserRouter>
      <div className="router">
        <Switch>
          <Route exact path="/" component={App} />
          <Route exact path="/health_checker/:healthChecker" component={App} />
          <Route exact path="/health_checker/:healthChecker/:ichiba_chat_referrer_id" component={App} />
          <Route exact path="/health_checker/:healthChecker/:ichiba_chat_referrer_id/:conversationCase" component={App} />
          <Route exact path="/rnt_chat_ref/:ichiba_chat_referrer_id" component={App} />
          <Route exact path="/rnt_chat_ref/:ichiba_chat_referrer_id/:conversationCase" component={App} />
          <Route exact path="/:conversationCase" component={App} />
        </Switch>
      </div>
    </BrowserRouter>,
    document.getElementById('root')
  );
}

function showTravelSD() {
  const sidCookie = Cookies.get('sid');
  const domain = document.domain;
  if(domain === 'hotel-chat.travel-sd.faq.rakuten.net' && !sidCookie) {
    return window.location.assign('https://manage.travel.rakuten.co.jp/portal/inn/mp_kanri.main');
  }
  return showDefault();
}

switch (process.env.REACT_APP_SERVICE) {
  case 'merchant':
    shopMerchant();
    break
  case 'merchant-internal':
    shopMerchantInternal();
    break
  case 'direct':
  case 'fashion':
    showFirstParty();
    break;
  case 'travel-sd':
    showTravelSD();
    break
  default:
    showDefault();
    break
};
