import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/FormErrMsgActions"
import { settings } from "../config/settings"

class FormErrMsgStore extends ReduceStore {

  getInitialState() {
    return settings.formErrMsg
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.clear:
        return settings.formErrMsg
      case keys.change:
        const { item, value } = action
        return  {...state, [item]:value }
      default:
        return state
    }
  }
}

export default new FormErrMsgStore(Dispatcher)
