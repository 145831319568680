import React from "react"
import CustomReactMarkdown from "./CustomReactMarkdown";

export default ({text, clickButton, disabled, value, type, mdValue}) => {
  const onClick =(event) => {
    clickButton(event, text, value, type, mdValue)
  }
  return (
  <button className="radiusBtn" onClick={onClick} value={value} disabled={disabled}>
    <CustomReactMarkdown children={text} />
  </button>
)}
