import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/FormFormatActions"
import { forms } from "../config/settings"

class SkillStore extends ReduceStore {

  getInitialState() {
    return ''
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.set: {
        const targetSkill = forms[action.formKey].skill || action.categoryKey
        return targetSkill === 'NO_CATEGORY' ? '' : targetSkill
      }
      case keys.setSkill: {
        const targetSkill = action.skill || state
        return targetSkill === 'NO_CATEGORY' ? '' : targetSkill
      }
      default:
        return state
    }
  }
}

export default new SkillStore(Dispatcher)
