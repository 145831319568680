import React, { useState } from "react";

function PurchaseHistoryItem({ orderItem, selectItem }) {
  const [checked, setChecked] = useState(false)
  const { orderNumber, orderDate, shopName, imageUrl } = orderItem
  const onClick = () => {
    const updatedValue = !checked
    setChecked(updatedValue)
    selectItem(orderNumber, updatedValue)
  }

  return (
    <div className="modalInput__orderItem__container">
      <img
        className="modalInput__orderItem__image"
        onClick={onClick}
        src={imageUrl || '/img/noimage_noPicture.png'}
        alt="itemImage"
      />
      <div className="modalInput__orderItem__checkbox__container">
        <input
          className="modalInput__orderItem__checkbox"
          type="checkbox"
          onChange={onClick}
          checked={checked}
        />
        <div
          className="modalInput__orderItem__orderInfo__container"
          onClick={onClick}
        >
          <div className="modalInput__orderItem__orderInfo__date">
            {orderDate}
          </div>
          <div>{shopName}</div>
        </div>
      </div>
    </div>
  )
}

export default ({ purchaseHistory, onChange }) => {
  const [selectedItems, setSelectedItems] = useState([])
  const selectItem = (orderNumber, checked) => {
    const baseValue = selectedItems.filter(item => item.orderNumber !== orderNumber)
    const selectedOrder = purchaseHistory.find(item => item.orderNumber === orderNumber) || {}
    const updatedValue = checked
      ? [
          ...baseValue,
          {
            orderNumber,
            shopUrl: selectedOrder.shopUrl || '',
            itemName: selectedOrder.itemName || ''
          }
        ]
      : baseValue
    onChange(updatedValue)
    setSelectedItems(updatedValue)
  }

  return (
    <div className="modalInput__purchaseHistory__container">
      {purchaseHistory.map((item, index) =>
        <PurchaseHistoryItem
          orderItem={item}
          selectItem={selectItem}
          key={`purchase-history-item-${index}`}
        />
      )}
    </div>
  )
}