import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/StatusActions"
import { settings } from "../config/settings"

class StatusStore extends ReduceStore {

  getInitialState() {
    return settings.status
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.connect:
      case keys.disconnect:
      case keys.error:
      case keys.reconnect:
      case keys.reconnectAttempt:
      case keys.reconnectError:
      case keys.reconnectFailed:
      case keys.reconnecting:
      case keys.initialize:
        return action

      default:
        return state
    }
  }
}

export default new StatusStore(Dispatcher)
