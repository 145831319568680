import React from "react"

export default ({ images, disabled, clickButton }) => {
  const clickAction = (event,text,value,type) => {
    if(!type || !value ) return
    if(!disabled)clickButton(event,text,value,type)
  }
  switch (images.length) {
    case 0: return (null)
    case 1:{
      const { tap = {} } = images[0]
      const { value, type } = tap
      return (<div className="imageContainer">
        <div className="wholeImageContainer" onClick={(event)=>clickAction(event,value,value,type)} >
          <img alt="" className="wholeImage" src={images[0].url} />
        </div>
      </div>)
    }
    case 2:
      return (<div className="imageContainer">
        <div className="halfImageContainer"　onClick={(event)=>clickAction(event,images[0].tap.value,images[0].tap.value,images[0].tap.type)}>
          <img alt="" className="halfImage" src={images[0].url} />
        </div>
        <div className="halfImageContainer"　onClick={(event)=>clickAction(event,images[1].tap.value,images[1].tap.value,images[1].tap.type)}>
          <img alt="" className="halfImage" src={images[1].url}/>
        </div>
      </div>)
    case 3:
      return (<div className="imageContainer">
        <div className="mainImageContainer" onClick={(event)=>clickAction(event,images[0].tap.value,images[0].tap.value,images[0].tap.type)}>
          <img alt="" className="mainImage" src={images[0].url} />
        </div>
        <div className="subImagesContainer">
          <div className="subImageContainer" onClick={(event)=>clickAction(event,images[1].tap.value,images[1].tap.value,images[1].tap.type)}>
            <img alt="" className="subImage" src={images[1].url}/>
          </div>
          <div className="subImageContainer" onClick={(event)=>clickAction(event,images[2].tap.value,images[2].tap.value,images[2].tap.type)}>
            <img alt="" className="subImage" src={images[2].url}/>
          </div>
        </div>
      </div>)

    default:
      return (<div className="imageContainer">
        <div className="mainImageContainer" onClick={(event)=>clickAction(event,images[0].tap.value,images[0].tap.value,images[0].tap.type)}>
          <img alt="" className="mainImage" src={images[0].url} />
        </div>
        <div className="subImagesContainer" onClick={(event)=>clickAction(event,images[1].tap.value,images[1].tap.value,images[1].tap.type)}>
          <div className="subImageContainer"　onClick={clickAction} value={images[1].tap.value}  type={images[1].tap.type}>
            <img alt="" className="subImage" src={images[1].url} />
          </div>
          <div className="subImagePlusContainer" onClick={(event)=>clickAction(event,images[2].tap.value,images[2].tap.value,images[2].tap.type)}>
            <div className="restFilter" src={images[2].url} >+{images.length - 3}</div>
            <img alt="" className="subImage" src={images[2].url}  />
          </div>
        </div>
      </div>)
  }
}
