import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { resources } from './config/resources';

i18n
.use(initReactI18next)
.init({
  resources,
  lng: 'ja',
  parseMissingKeyHandler: (key) => null,//keyに対応するobjectがなければ、何も表示しない
  fallbackLng: 'ja',
  whitelist: ['ja', 'en', 'zh_cn', 'zh_tw'],
  returnObjects: true
})

export default i18n;