import Dispatcher from "../Dispatcher"

export const keys = {
  set: Symbol("form-input:set"),
  change: Symbol("form-input:change"),
  clear: Symbol("form-input:clear"),
}

export default {
  set(itemset){
    Dispatcher.dispatch({
      type: keys.set,
      itemset
    })
  },
  change(item,value){
    Dispatcher.dispatch({
      type: keys.change,
      item,
      value,
    })
  },
  clear(orderNumber){
    Dispatcher.dispatch({
      type: keys.clear,
      orderNumber,
    })
  },
}