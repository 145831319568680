import React from 'react';
import Modal from 'react-modal';
import { Container } from "flux/utils"
import i18n from "../i18n";
import { isSmartPhone } from "chat-common"

import FormInputStore from '../stores/FormInputStore';
import FormErrMsgStore from '../stores/FormErrMsgStore';
import MessageStore from '../stores/MessageStore';
import SubmitStore from '../stores/SubmitStore';
import SkillStore from '../stores/SkillStore'
import ModalMessageStore from '../stores/ModalMessageStore'
import ModalWindowStore from '../stores/ModalWindowStore';
import QuestionnaireStore from '../stores/QuestionnaireStore';
import FormFormatStore from '../stores/FormFormatStore';
import UserInfoStore from '../stores/UserInfoStore';
import QueueStore from '../stores/QueueStore';

import FormActions from "../actions/FormInputActions"
import ModalWindowActions from "../actions/ModalWindowActions";
import MessageActions  from '../actions/MessageActions';
import Form from "../components/Form";
import SocketActions from '../actions/SocketActions';
import CaseStore from '../stores/CaseStore';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-40%',
    paddingBottom         : '0px',
    transform             : 'translate(-50%, -50%)',
    background            : '#e9eff2',
    maxHeight             : '100%',
    maxWidth              : '80%'
  }
};
Modal.setAppElement('#root')

class ModalWindowContainer extends React.Component {
  static getStores() {
    return [
      CaseStore,
      FormInputStore,
      FormErrMsgStore,
      MessageStore,
      SubmitStore,
      ModalWindowStore,
      QuestionnaireStore,
      FormFormatStore,
      UserInfoStore,
      SkillStore,
      ModalMessageStore,
      QueueStore,
    ]
  }

  static calculateState(prevState) {
    return {
      formInput: FormInputStore.getState(),
      formErrMsg: FormErrMsgStore.getState(),
      case: CaseStore.getState(),
      skill: SkillStore.getState(),
      modalMessage: ModalMessageStore.getState(),
      message: MessageStore.getState(),
      submit: SubmitStore.getState(),
      modalWindow: ModalWindowStore.getState(),
      questionnaire: QuestionnaireStore.getState(),
      formFormat: FormFormatStore.getState(),
      queue: QueueStore.getState(),
      userInfo: UserInfoStore.getState(),
    }
  }

  setScrollHeight() {
    const html = document.documentElement;
    const body = document.body;
    body.scrollTop = body.scrollHeight;
    html.scrollTop = html.scrollHeight;
  }
  
  _clearInfo() {
    const { orderNumber='' } = this.state.formInput;
    FormActions.clear(orderNumber)
  }
  
  closeModal = () => {
    this._clearInfo();
    ModalWindowActions.close(this.state.submit.opponent, i18n.t('cancelOperatorMessage'));
    MessageActions.addUserMessage(i18n.t('cancelOperatorMessage'))
    SocketActions.cancelReception()
  }

  submitModal = () => {
    const spn = new URLSearchParams(window.location.search).get('spn');
    Object.assign( this.state.formInput, { facilityNumber: spn, } );
    this._clearInfo();
    const form = this.state.formFormat.map(format => {
      return { ...format, value: this.state.formInput[format.id] || ''}
    })
    ModalWindowActions.submit(
      this.state.submit.opponent,
      form,
      this.state.skill,
      this.state.message.contents,
      this.state.questionnaire
    );
  }

  render() {
    const caseId = this.state.case
    const skill = this.state.skill
    const count = this.state.queue
    ? (caseId && skill)
      ?this.state.queue.indexOf(`${skill}:${caseId}`)
      :this.state.queue.length
    : " − "
    if(isSmartPhone(navigator.userAgent)){
      customStyles.content.width = '82%'
      customStyles.content.maxWidth = '82%'
    }
    return (
      <Modal
        isOpen={this.state.modalWindow.isOpen}
        onAfterOpen={this.setScrollHeight}
        onRequestClose={this.closeModal}
        onAfterClose={this.setScrollHeight}
        style={customStyles}
        overlayClassName={'modal__overlay'}
        bodyOpenClassName={'modal__body'}
        htmlOpenClassName={'modal__html'}>
        <Form
          modalMessage={this.state.modalMessage}
          userInfo={this.state.userInfo}
          formFormat={this.state.formFormat}
          queue={count}
          formInput={this.state.formInput}
          formErrMsg={this.state.formErrMsg}
          onCloseModal={this.closeModal}
          onSubmitModal={this.submitModal}
          skill={skill}
        />
      </Modal>
    );
  }
}

export default Container.create(ModalWindowContainer)