import React from "react"
import CustomReactMarkdown from "./CustomReactMarkdown";

export default ({ text, href, clickLink}) => {
  const clickLinkAction = () => {
    clickLink(href)
  }
  return (
    <div className="squareBtn"  onClick={clickLinkAction}>
      <div>
        <a href={href} title="faq" target="_blank" rel="noopener noreferrer">
          <CustomReactMarkdown children={text} />
        </a>
      </div>
    </div>
  )
}
