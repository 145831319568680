import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/FormFormatActions"
import { settings, forms } from "../config/settings"

class FormFormatStore extends ReduceStore {

  getInitialState() {
    return settings.formFormat
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.set:
        //フォーム全体のバリデーションチェック用
        const { formKey, categoryKey, purchaseHistory } = action
        return  forms[formKey].form.map((info) => {
          const isValid = !info.required
          if (info.id === 'loginUserInquiryCategory') {
            const initialValue = categoryKey === 'NO_CATEGORY' ? '' : categoryKey
            return { ...info, isValid, initialValue }
          }
          return Array.isArray(purchaseHistory)
            ? { ...info, isValid, purchaseHistory }
            : { ...info, isValid }
        })
      case keys.changeDefaultValue: {
        const { item, value } = action
        return state.map(info => 
          (info.id === item) ? { ...info, defaultValue: value} : info
        )
      }
      case keys.valid: {
        const { item, isValid } = action
        return state.map(info => 
          (info.id === item) ? { ...info, isValid} : info
        )
      }
      default:
        return state
    }
  }
}

export default new FormFormatStore(Dispatcher)
