import React from "react"
import MenuActions from "../actions/MenuActions"
import { createInquiryUrl } from "chat-common"
import i18n from "../i18n"

export default ({id, device, name, submit, queue, link, reset, target, disabled, isSkipSubmit, botMaintenance, shop, order, onSubmit, openUrl, }) => {
  const translatedSubmit = i18n.t(submit)
  const { isMaintenance=false } = botMaintenance
  const { shopId='' } = shop
  const { orderNumber='' } = order
  // PC pcのリンクを開く
  // SP spのリンクを開く
  // submitアクション がだめならリンクを開く
  // reception中はdisable
  // 
  const onClick = () => {
    //linkがなければ問答無用でsubmit
    //linkがあるがisSkipSubmitが有効の場合 openUrl
    //linkがあり、isSkipSubmitが無効の場合　かつ submitがあればonSubmit
    //linkがあり、isSkipSubmitが無効の場合　かつ submitがなければopenUrl

    if(disabled){
      return;
    }
    MenuActions.close()

    //メンテナンス時はopenUrl
    if (process.env.REACT_APP_SERVICE === 'direct' && isMaintenance) {
      switch (id) {
        case 'form':
          return openUrl(createInquiryUrl({shopId: shopId || '', orderNumber: orderNumber || ''}), target)
        case 'reset':
        case 'delivery':
          break
        default:
          return openUrl(link[device], target)
      }
    }

    if(reset){
      return window.location.reload();
    }
    if((!link || !isSkipSubmit) && translatedSubmit){
      return onSubmit(translatedSubmit)
    }
    //linkがありsubmitがなければ
    return openUrl(link[device], target)
  }
  const count = queue
  ? queue.length
  : " − "
  const maxQueueCount = process.env.REACT_APP_MAX_QUEUE_COUNT
  const replacedData = {
    queue: (maxQueueCount && queue && count >= maxQueueCount) ? maxQueueCount : count,
    more: queue && count >= maxQueueCount ? '以上' : ''
  };
  const translatedText = (name) ? i18n.t(name, replacedData) : []
  const arrayName = (translatedText instanceof Array) ? translatedText : []
  const checkedClassName =
    (process.env.REACT_APP_SERVICE === 'direct') ? 
      `hamburgerMenu__item hamburgerMenu__item_noIcon` : 
      (process.env.REACT_APP_SERVICE === 'ichiba') ? 
        `hamburgerMenu__item hamburgerMenu__item_ichiba hamburgerMenu__item--ichiba-${id}` : 
          `hamburgerMenu__item hamburgerMenu__item--${id}`;
  return (
  <button className={checkedClassName}  onClick={onClick} disabled={disabled} >
   {arrayName.map((n,index)=>(<p key={index}>{n}</p>))}
  </button>
)}
