import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/StaffActions"

class StaffStore extends ReduceStore {
  getInitialState() {
    return ""
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.initialize:
        return ""
      case keys.set:
        return action.staffId
      case keys.clear:
        return ""
      default:
        return state
    }
  }
}

export default new StaffStore(Dispatcher)
