import Dispatcher from "../Dispatcher"
import SocketActions from "./SocketActions";

export const keys = {
  initialize: Symbol("disconnect:initialize"),
  show: Symbol("disconnect:show"),
  hide: Symbol("disconnect:hide"),
  disable: Symbol("disconnect:disable"),
  enable: Symbol("disconnect:enable"),
  useDisconnect: Symbol("disconnect:use-disconnect"),
  abandon: Symbol("disconnect:abondon"),
  disconnect: Symbol("disconnect:disconnect"),
}

export default {
  initialize(){
    Dispatcher.dispatch({
      type: keys.initialize,
    })
  },
  show(){
    Dispatcher.dispatch({
      type: keys.show,
      isDisplay: true,
    }) 
  },
  hide(){
    Dispatcher.dispatch({
      type: keys.hide,
      isDisplay: false,
    }) 
  },
  disable(){
    Dispatcher.dispatch({
      type: keys.disable,
      disabled: true,
    }) 
  },
  enable(){
    Dispatcher.dispatch({
      type: keys.enable,
      disabled: false,
    }) 
  },
  useDisconnect(){
    Dispatcher.dispatch({
      type: keys.useDisconnect,
    }) 
  },
  //Action
  abandon(caseId, sessonId){
    Dispatcher.dispatch({
      type: keys.abandon,
    }) 
    SocketActions.abandonChat(caseId, sessonId)
    SocketActions.checkChatDisconnect()
  },
  disconnect(caseId,sessionId){
    Dispatcher.dispatch({
      type: keys.disconnect,
    }) 
    SocketActions.disconnectChat(caseId,sessionId)
    SocketActions.checkChatDisconnect()
  },
}
