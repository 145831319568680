import React from "react"
import CustomReactMarkdown from "./CustomReactMarkdown";

export default ({text, value, clickLink}) => {
  const onClick = () => {
    clickLink(value)
  }
  return (<div className="squareBtn squareBtn--filled squareBtn--arrow" onClick={onClick}>
        <a className="squareBtn__text"　href={value} title="direct" target="_blank" rel="noopener noreferrer">
          <CustomReactMarkdown children={text} />
        </a>
  </div>
)}
