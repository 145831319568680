import Dispatcher from "../Dispatcher"

export const keys = {
  initialize: Symbol("session:initialize"),
  set: Symbol("session:set"),
}

export default {
  initialize(){
    Dispatcher.dispatch({
      type: keys.initialize
    })
  },
  set(sessionId){
    Dispatcher.dispatch({
      type: keys.set,
      sessionId,
    })
  },
}