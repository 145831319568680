import Dispatcher from "../Dispatcher"
import jump from "jump.js"
import { debounce } from 'lodash'

export const keys = {
  scroll: Symbol("scroll:from-event-listener"),
}

const scroll = debounce((className, duration = 600) => {
  let jumpOption = {duration}
  if (window.innerWidth < 700) {
    let header = document.getElementsByClassName('chatHeader')[0].getBoundingClientRect().height
    Object.assign(jumpOption,{offset: -header - 10})
  }
  jump(className,jumpOption)
},100)

export default {
  scroll(){
    const scroll = window.scrollY || window.pageYOffset;
    const streamLength = document.getElementById('message_stream') && document.getElementById('message_stream').clientHeight
    const displayedLength = window.innerHeight
    const isDisplay = (scroll < (streamLength - displayedLength) * 2 / 3) ? 'is-display' : '';
    Dispatcher.dispatch({
      type: keys.scroll,
      isDisplay,
    })
  },
  scrollDown(className){
    scroll(className)
  }
}