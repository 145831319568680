import React from 'react';
import i18n from "../i18n";

const FormCancelButton = ({ onCloseModal }) => {
  return (
    <button
      type='button'
      id='modalCancelButton'
      className={'modalInput__button modalInput__cancel'}
      style={{ marginRight: '7px' }}
      onClick={onCloseModal}
    >
      {i18n.t('cancelButton')}
    </button>
  )
}

export default FormCancelButton;
