import React from "react"
import moment from "moment"
import i18n from "../i18n"

export default ({ text, now, fileimg, contentType="", contentUrl, isSending, isImage=contentType.startsWith("image"), balloonId, scrollToBottom }) => {
  React.useEffect(() => {
    scrollToBottom(`.${balloonId}`)
  },[])

  let lines = text.split("\n")
  let time = moment(now).format("HH:mm")
  const wordNormalClass = (i18n.language === 'en') ? 'messageWordNormal' : ''
  return (
    <div className={`userMessage u-clearfix ${balloonId}`}>
      <div className="userMessage__inner">
        <div className="userMessage__grid userMessage__grid--time">
          <div className="userMessage__time">{time}</div>
        </div>
        <div className="userMessage__grid">
          <div className={`userMessage__balloon ${wordNormalClass}`}>
            {(contentUrl)?
            <div className="userMessage__uploaded">
              {(isImage)
                ?<img src={contentUrl} alt="" />
                :<img src={`${fileimg}?type=${contentType}`} alt="" style={{width:'48px'}} />}
              {(isSending)
              ? <div  className="userMessage__nowUploading">
                  <img className="loadingImg" src="/img/circlepic.png" alt="" />
                </div>
              : ''}
            </div>
            : ''}
            {lines.map((line, index) => (
              <p key={index}>{line}</p>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
