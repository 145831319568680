import React, { useState, useEffect } from 'react';
import i18n from "../i18n";
import { selectItemsMap } from '../config/settings'

function getSelectItemValue(selectItemsKey, item) {
  const { value } = selectItemsMap[selectItemsKey].find(({ item: _item }) => item === _item)
  return value
}

export default ({ id, required, style, onChange: setValues, onBlur, selectItemsKey, isSpace, initialValue }) => {
  const [currentSelected, setCurrentSelected] = useState(initialValue || '')

  useEffect(() => {
    if (initialValue) onChange({ target: { value: initialValue } })
  }, [])

  const selectItems = i18n.t(`modal.selectItems.${selectItemsKey}`);
  const spaceClass = (isSpace) ? 'modalInput__space' : ''
  const onChange = event => {
    setValues(event)
    setCurrentSelected(event.target.value)
  }

  return (
    <select
      id={id}
      onChange={onChange}
      onBlur={onBlur}
      style={style}
      required={required}
      className={`${id} modalInput__selectField ${spaceClass}`}
      value={currentSelected}
    >
      <option value="" key={`key-${id}-init`} hidden></option>
      {selectItems.map((item, index) => {
        const value = getSelectItemValue(selectItemsKey, item)
        return (
          <option
            value={value}
            key={`key-${id}-${index}`}
          >
            {item}
          </option>
        )
      })}
    </select>
  );
}