import Dispatcher from "../Dispatcher"

export const keys = {
  initialize: Symbol("human-chat-status:initialize"),
  isTimeout: Symbol("human-chat-status:is-timeout"),
  isRequesting: Symbol("human-chat-status:is-requesting"),
  isConnected: Symbol("human-chat-status:is-connected"),
  isTransfering: Symbol("human-chat-status:is-transfering"),
  isDisconnectedByAgent: Symbol("human-chat-status:is-disconnected-by-agent"),
  isDisconnectedByUser: Symbol("human-chat-status:is-disconnected-by-user"),
}

export default {
  initialize(){
    Dispatcher.dispatch({
      type: keys.initialize,
      text:'',
      classification: ''
    })
  },
  isRequesting(){
    Dispatcher.dispatch({
      type: keys.isRequesting,
      text: 'humanChatStatusMessage.connectingOperator',
      classification: 'is-processing'
    })
  },
  isConnected(data){
    Dispatcher.dispatch({
      type: keys.isConnected,
      text: 'humanChatStatusMessage.operator',
      data,
      classification: 'is-complete'
    })
  },
  isTransfering(){
    Dispatcher.dispatch({
      type: keys.isTransfering,
      text: 'statusMessage.transferOperator',
      classification: 'is-processing'
    })
  },
  isTimeout(){
    Dispatcher.dispatch({
      type: keys.isTimeout,
      text: 'humanChatStatusMessage.timeoutOperator',
      classification: 'is-complete'
    })
  },
  isDisconnectedByAgent(){
    Dispatcher.dispatch({
      type: keys.isDisconnectedByAgent,
      text: 'humanChatStatusMessage.disconnectOperator',
      classification: 'is-complete'
    })
  },
  isDisconnectedByUser(){
    Dispatcher.dispatch({
      type: keys.isDisconnectedByUser,
      text: 'humanChatStatusMessage.disconnectUser',
      classification: 'is-complete'
    })
  }
}