import Dispatcher from "../Dispatcher"

export const keys = {
  initialize: Symbol("case:initialize"),
  set: Symbol("case:set"),
}

export default {
  initialize(){
    Dispatcher.dispatch({
      type: keys.initialize
    })
  },
  set(caseId){
    Dispatcher.dispatch({
      type: keys.set,
      caseId,
    })
  },
}