import Dispatcher from "../Dispatcher"

export const keys = {
  set: Symbol("form-format:set"),
  changeDefaultValue: Symbol("form-format:change-default-value"),
  valid: Symbol("form-format:valid"),
  clear: Symbol("form-format:clear"),
  setSkill: Symbol("form-format:set-skill")
}

export default {
  clear(orderNumber){
    Dispatcher.dispatch({
      type: keys.clear,
      orderNumber,
    })
  },
  changeDefaultValue(item,value){
    Dispatcher.dispatch({
      type: keys.changeDefaultValue,
      item,
      value,
    })
  },
  valid(item,isValid){
    Dispatcher.dispatch({
      type: keys.valid,
      item,
      isValid,
    })
  },
  set(formKey, categoryKey, purchaseHistory){
    Dispatcher.dispatch({
      type: keys.set,
      formKey,
      categoryKey,
      purchaseHistory
    })
  },
  setSkill(skill) {
    Dispatcher.dispatch({ type: keys.setSkill, skill })
  }
}