/* initialState in Store is reading this setting
*/
const settings = {
  title: 'title',
  queue: null,
  displayQueueCategory: '',
  calcWaitCountType: 'ALL',
  wsurl: undefined,
  droparea: { visible: false, disabled: true, src: '/img/sendfile_drop.png', descriptions: 'fileDrop', fileimg: '/img/sendfile_message_icon_pc.png' },
  announcement: { title: "", msg: "" },
  botError: {
    device: 'pc',
    isDisplay: false,
    messages: 'botError.message',
    options: [
      {
        btntype: 'submit',
        disabled: false,
        titles: 'botError.options.operator.titles',
        submit: 'botError.options.operator.submit',
      },
      {
        btntype: 'openLink',
        disabled: false,
        titles: 'botError.options.mail.titles',
        link: {
          ja: {
            pc: 'https://ichiba.faq.rakuten.net/form/summary-inquiry',
            sp: 'https://ichiba-smp.faq.rakuten.net/form/inquiry',
          }
        },
      },
      {
        btntype: 'openLink',
        disabled: false,
        titles: 'botError.options.rgroup.titles',
        link: {
          ja: {
            pc: 'https://www.rakuten.co.jp/sitemap/inquiry.html',
            sp: 'https://www.rakuten.co.jp/sitemap/sp/inquiry.html',
          }
        }
      }
    ]
  },
  botMaintenance: { isMaintenance: false },
  chatSetting: {},
  chatMessage: {
    device: '',
    botIcon: '/img/chatbot_icon_pc.png',
    operatorIcon: '/img/operator_message_icon.png',
    radius: '50%',
    bg: '',
    contents: []
  },
  connection: {},
  disconnect: { isDisplay: false, disabled: true, useDisconnect: false },
  icon: {
    logoSrc: { ja: '/img/chatbot_icon_pc.png' },
    logoClassName: '',
    radius: '50%',
    bg: '#fff'
  },
  input: {
    value: '',
    placeholder: 'placeholder.bot',
    disabled: false,
  },
  laAvailability: {},
  menu: {
    device: 'pc',
    isActive: "",
    isDisplay: true,
    disabled: false,
    menus: [
      {
        id: 'operator',
        isSkipSubmit: false,
        isDisplay: false,
        name: 'menus.operator.name',
        submit: 'menus.operator.submit',
        link: undefined
      },
      {
        id: 'tel',
        isSkipSubmit: false,
        isDisplay: false,
        name: 'menus.tel.name',
        submit: 'menus.tel.submit',
        link: {
          pc: 'https://ichiba.faq.rakuten.net/detail/000006841',
          sp: 'https://ichiba.faq.rakuten.net/detail/000006841',
        },
      },
      {
        id: 'mail',
        isSkipSubmit: false,
        isDisplay: false,
        name: 'menus.mail.name',
        submit: 'menus.mail.submit',
        link: {
          pc: 'https://ichiba.faq.rakuten.net/form/summary-inquiry',
          sp: 'https://ichiba.faq.rakuten.net/form/summary-inquiry',
        }
      },
      {
        id: 'rgroup',
        disable: false,
        isSkipSubmit: true,
        isDisplay: false,
        name: 'menus.rgroup.name',
        submit: undefined,
        link: {
          pc: 'https://www.rakuten.co.jp/sitemap/inquiry.html',
          sp: 'https://www.rakuten.co.jp/sitemap/sp/inquiry.html',
        }
      },
      {
        id: 'x',
        disable: false,
        isSkipSubmit: true,
        isDisplay: false,
        name: 'menus.x.name',
        submit: undefined,
        link: {
          pc: 'https://twitter.com/RakutenIchibaCS',
          sp: 'https://twitter.com/RakutenIchibaCS',
        }
      },
      {
        id: 'reset',
        isSkipSubmit: false,
        isDisplay: false,
        name: 'menus.reset.name',
        submit: 'menus.reset.submit',
        link: undefined
      }
    ]
  },
  message: {
    device: '',
    botIcon: '/img/chatbot_icon_pc.png',
    operatorIcon: '/img/operator_message_icon.png',
    radius: '50%',
    bg: '',
    contents: []
  },
  modalWindow: {
    isOpen: false,
    icon: {
      logoSrc: {
        ja: '/img/chatbot_icon_pc.png',
      },
    },
    displayQueueMap: {},
  },
  name: { name: 'profileName.bot' },
  notification: {
    ignore: false,
    disableActiveWindow: false,
    askAgain: false,
    timeout: 5000,
    title: "",
    options: {
      body: '',
      icon: '/img/operator_message_icon.png',
      lang: '',
      image: "a",
      data: {}
    }
  },
  opponent: {},
  order: { orderNumber: '' },
  personel: { personel: '' },
  scroll: { isDisplay: '' },
  shop: { shopId: '' },
  socket: "",
  socketAction: {
    upload: true
  },
  statusLamp: {
    status: "online",
    isDisplayStatus: true,
  },
  status: {
    text: "",
    classification: ""
  },
  submit: { opponent: 'bot', disabled: true, grayOut: false, screenSize: '' },
  suggestion: {
    device: 'pc',
    index: 0,
    posX: "16px",
    items: [],
    inlineStyle: {
      transform: "translateX(0)",
    }
  },
  typing: { name: '', isDisplay: false },
  upload: {
    isDisplay: false,
    icon: '/img/sendimage_icon_sp.png',
    fileimg: '/img/sendfile_message_icon_pc.png',
    accept: 'image/*',
    disabled: false,
  },
  formInput: {},
  formErrMsg: {},
  formFormat: []
}

const forms = {
  'no_login_id_default': {
    skill: "ID",
    message: "defaultMessage",
    form: [
      { id: 'email', name: 'メールアドレス', placeholder: '', type: 'email', required: true, maxLength: 100, forDisplay: true,        diver: 'email', echoes: 'emailAddress', logFormKey: 'email',},
      { id: 'lastName', name: '姓', placeholder: '例)楽天', type: 'text', required: true, maxLength: 190,   diver: 'lastName',　echoes: 'lastName', logFormKey: 'lastName',},
      { id: 'firstName', name: '名', placeholder: '例)太郎', type: 'text', required: true, maxLength: 190, diver: 'firstName',　echoes: 'firstName', logFormKey: 'firstName',},
      { id: 'kana', name: 'カナ', placeholder: '例)ラクテンタロウ', type: 'text', required: true, maxLength: 190,  regexp: '^([ァ-ン]|ー|－|―)+$' },
      { id: 'tel', name: '電話番号', placeholder: '例)08012345678', type: 'tel', required: false, maxLength: 24, echoes: 'phone', logFormKey: 'phone', regexp: '^([0０]([0-9０-９][-|‐|ー|－|―|−]?[0-9０-９]{4}|[0-9０-９]{2}[-|‐|ー|－|―|−]?[0-9０-９]{3}|[0-9０-９]{3}[-|‐|ー|－|―|−]?[0-9０-９]{2}|[0-9０-９]{4}[-|‐|ー|－|―|−]?[0-9０-９])[-|‐|ー|－|―|−]?[0-9０-９]{4}|[0０][789７８９][0０][--|‐|ー|－|―|−]?[0-9０-９]{4}[-|‐|ー|－|―|−]?[0-9０-９]{4})$' },
      { id: 'birthDay', name: '生年月日', placeholder: '例)19990110', type: 'text', required: false, maxLength: 8, echoes: 'dateOfBirth', regexp: '^(?!([2２][1235679１２３５６７９]|[1１][01345789０１３４５７８９])[0０][0０][0０][2２][2２][9９])(([12１２][0-9０-９]{3}([0０][1１]|[0０][3３]|[0０][5５]|[0０][7７]|[0０][8８]|[1１][0０]|[1１][2２])([0０][1-9１-９]|[12１２][0-9０-９]|[3３][01０１]))|([12１２][0-9０-９]{3}([0０][4４]|[0０][6６]|[0０][9９]|[1１][1１])([0０][1-9１-９]|[12１２][0-9０-９]|[3３][0０]))|([12１２][0-9０-９]{3}[0０][2２]([0０][1-9１-９]|[1１][0-9０-９]|[2２][0-8０-８]))|([12１２][0-9０-９]([02468０２４６８][048０４８]|[13579１３５７９][26２６])[0０][2２][2２][9９]))$' },
      { id: 'postalCode', name: '郵便番号', placeholder: '例)1010001', type: 'text', required: false, maxLength: 7, echoes: 'postalCode', regexp: '^([0-9]|[０-９]){7}$' },
      { id: 'address', name: '住所', placeholder: '', type: 'text', required: false, maxLength: 100 },
      { id: 'inquiryContent', name: 'お問い合わせ内容', placeholder: '例)注文した商品のキャンセル方法がわからず困っています。誤って注文してしまったのでキャンセルしたいです。', type: 'textarea', required: true, maxLength: 100 },
      { id: 'disclaimer', alias: '免責確認', name: 'お問い合わせ内容によっては、楽天市場の各グループ会社/出店店舗へお客様の情報を共有します。場合により共有先からのご連絡となりますことをご了承ください。', type: 'checkbox', required: true },
    ]
  },
  'no_login_pnt_default': {
    skill: "PNT",
    message: "defaultMessage",
    form: [
      { id: 'email', name: 'メールアドレス', placeholder: '', type: 'email', required: true, maxLength: 100, forDisplay: true,        diver: 'email', echoes: 'emailAddress', logFormKey: 'email',},
      { id: 'inquiryContent', name: 'お問い合わせ内容', placeholder: '例)注文した商品のキャンセル方法がわからず困っています。誤って注文してしまったのでキャンセルしたいです。', type: 'textarea', required: true, maxLength: 100 },
      { id: 'disclaimer', alias: '免責確認', name: 'お問い合わせ内容によっては、楽天市場の各グループ会社/出店店舗へお客様の情報を共有します。場合により共有先からのご連絡となりますことをご了承ください。', type: 'checkbox', required: true },
    ]
  },
  'no_login_shp_default': {
    skill: "SHP",
    message: "defaultMessage",
    form: [
      { id: 'email', name: 'メールアドレス', placeholder: '', type: 'email', required: true, maxLength: 100, forDisplay: true,        diver: 'email', echoes: 'emailAddress', logFormKey: 'email',},
      { id: 'lastName', name: '姓', placeholder: '例)楽天', type: 'text', required: true, maxLength: 190,   diver: 'lastName',　echoes: 'lastName', logFormKey: 'lastName',},
      { id: 'firstName', name: '名', placeholder: '例)太郎', type: 'text', required: true, maxLength: 190, diver: 'firstName',　echoes: 'firstName', logFormKey: 'firstName',},
      { id: 'orderNumber', name: '受注番号', placeholder: '', type: 'text', required: false, maxLength: 100, diver: 'orderNumber',　echoes: 'orderNumber', logFormKey: 'orderNumber', regexp: '^([0-9]|[０-９]){6}(-|‐|ー|－|―)([0-9]|[０-９]){8}(-|‐|ー|－|―)([0-9]|[０-９])*$' },
      { id: 'inquiryContent', name: 'お問い合わせ内容', placeholder: '例)注文した商品のキャンセル方法がわからず困っています。誤って注文してしまったのでキャンセルしたいです。', type: 'textarea', required: true, maxLength: 100 },
      { id: 'disclaimer', alias: '免責確認', name: 'お問い合わせ内容によっては、楽天市場の各グループ会社/出店店舗へお客様の情報を共有します。場合により共有先からのご連絡となりますことをご了承ください。', type: 'checkbox', required: true },
    ]
  },
  'no_login_pay_default': {
    skill: "SHP",
    message: "defaultMessage",
    form: [
      { id: 'email', name: 'メールアドレス', placeholder: '', type: 'email', required: true, maxLength: 100, forDisplay: true,        diver: 'email', echoes: 'emailAddress', logFormKey: 'email',},
      { id: 'lastName', name: '姓', placeholder: '例)楽天', type: 'text', required: true, maxLength: 190,   diver: 'lastName',　echoes: 'lastName', logFormKey: 'lastName',},
      { id: 'firstName', name: '名', placeholder: '例)太郎', type: 'text', required: true, maxLength: 190,  diver: 'firstName',　echoes: 'firstName', logFormKey: 'firstName',},
      { id: 'orderNumber', name: '受注番号', placeholder: '', type: 'text', required: false, maxLength: 100, diver: 'orderNumber',　echoes: 'orderNumber', logFormKey: 'orderNumber', regexp: '^([0-9]|[０-９]){6}(-|‐|ー|－|―)([0-9]|[０-９]){8}(-|‐|ー|－|―)([0-9]|[０-９])*$' },
      { id: 'inquiryContent', name: 'お問い合わせ内容', placeholder: '例)注文した商品のキャンセル方法がわからず困っています。誤って注文してしまったのでキャンセルしたいです。',type: 'textarea', required: true, maxLength: 100 },
      { id: 'disclaimer', alias: '免責確認', name: 'お問い合わせ内容によっては、楽天市場の各グループ会社/出店店舗へお客様の情報を共有します。場合により共有先からのご連絡となりますことをご了承ください。', type: 'checkbox', required: true },
    ]
  },
  'no_login_tec_default': {
    skill: 'TEC',
    message: "defaultMessage",
    form: [
      { id: 'email', name: 'メールアドレス', placeholder: '', type: 'email', required: true, maxLength: 100, forDisplay: true,        diver: 'email', echoes: 'emailAddress', logFormKey: 'email',},
      { id: 'inquiryContent', name: 'お問い合わせ内容', placeholder: '例)注文した商品のキャンセル方法がわからず困っています。誤って注文してしまったのでキャンセルしたいです。', type: 'textarea', required: true, maxLength: 100 },
      { id: 'disclaimer', alias: '免責確認', name: 'お問い合わせ内容によっては、楽天市場の各グループ会社/出店店舗へお客様の情報を共有します。場合により共有先からのご連絡となりますことをご了承ください。', type: 'checkbox', required: true },
    ]
  },
  'no_login_dev_default': {
    skill: 'DEV',
    message: "defaultMessage",
    form: [
      { id: 'email', name: 'メールアドレス', placeholder: '', type: 'email', required: true, maxLength: 100, forDisplay: true,        diver: 'email', echoes: 'emailAddress', logFormKey: 'email',},
      { id: 'disclaimer', alias: '免責確認', name: 'お問い合わせ内容によっては、楽天市場の各グループ会社/出店店舗へお客様の情報を共有します。場合により共有先からのご連絡となりますことをご了承ください。', type: 'checkbox', required: true },
    ]
  },
  'no_login_id_change_birthday': {
    skill: 'ID',
    message: "messageWithoutShareInfo",
    form: [
      { id: 'lastName', name: '姓', placeholder: '例)楽天', type: 'text', required: true, maxLength: 190,   diver: 'lastName',　echoes: 'lastName', logFormKey: 'lastName',},
      { id: 'firstName', name: '名', placeholder: '例)太郎', type: 'text', required: true, maxLength: 190, diver: 'firstName',　echoes: 'firstName', logFormKey: 'firstName',},
      { id: 'kana', name: 'カナ', placeholder: '例)ラクテンタロウ', type: 'text', required: false, maxLength: 190, regexp: '^([ァ-ン]|ー|－|―)+$' },
      { id: 'email', name: 'メールアドレス', placeholder: '', type: 'email', required: true, maxLength: 100, forDisplay: true,        diver: 'email', echoes: 'emailAddress', logFormKey: 'email',},
      { id: 'tel', name: '電話番号', placeholder: '例)08012345678', type: 'tel', required: true, maxLength: 24, regexp: '^([0０]([0-9０-９][-|‐|ー|－|―|−]?[0-9０-９]{4}|[0-9０-９]{2}[-|‐|ー|－|―|−]?[0-9０-９]{3}|[0-9０-９]{3}[-|‐|ー|－|―|−]?[0-9０-９]{2}|[0-9０-９]{4}[-|‐|ー|－|―|−]?[0-9０-９])[-|‐|ー|－|―|−]?[0-9０-９]{4}|[0０][789７８９][0０][--|‐|ー|－|―|−]?[0-9０-９]{4}[-|‐|ー|－|―|−]?[0-9０-９]{4})$' },
      { id: 'birthDayOld', name: '誤っている生年月日(西暦)', placeholder: '例)19990101', type: 'text', required: true, maxLength: 8, regexp: '^(?!([2２][1235679１２３５６７９]|[1１][01345789０１３４５７８９])[0０][0０][0０][2２][2２][9９])(([12１２][0-9０-９]{3}([0０][1１]|[0０][3３]|[0０][5５]|[0０][7７]|[0０][8８]|[1１][0０]|[1１][2２])([0０][1-9１-９]|[12１２][0-9０-９]|[3３][01０１]))|([12１２][0-9０-９]{3}([0０][4４]|[0０][6６]|[0０][9９]|[1１][1１])([0０][1-9１-９]|[12１２][0-9０-９]|[3３][0０]))|([12１２][0-9０-９]{3}[0０][2２]([0０][1-9１-９]|[1１][0-9０-９]|[2２][0-8０-８]))|([12１２][0-9０-９]([02468０２４６８][048０４８]|[13579１３５７９][26２６])[0０][2２][2２][9９]))$' },
      { id: 'birthDayNew', name: '正しい生年月日(西暦)', placeholder: '例)19990110', type: 'text', required: true, maxLength: 8, regexp: '^(?!([2２][1235679１２３５６７９]|[1１][01345789０１３４５７８９])[0０][0０][0０][2２][2２][9９])(([12１２][0-9０-９]{3}([0０][1１]|[0０][3３]|[0０][5５]|[0０][7７]|[0０][8８]|[1１][0０]|[1１][2２])([0０][1-9１-９]|[12１２][0-9０-９]|[3３][01０１]))|([12１２][0-9０-９]{3}([0０][4４]|[0０][6６]|[0０][9９]|[1１][1１])([0０][1-9１-９]|[12１２][0-9０-９]|[3３][0０]))|([12１２][0-9０-９]{3}[0０][2２]([0０][1-9１-９]|[1１][0-9０-９]|[2２][0-8０-８]))|([12１２][0-9０-９]([02468０２４６８][048０４８]|[13579１３５７９][26２６])[0０][2２][2２][9９]))$' },
      { id: 'birthDayChangeReason', name: '変更理由', type: 'select', required: true, selectItemsKey: 'birthDayChangeReason' },
      { id: 'confirmChangeBirthDay', name: '過去にお問い合わせで生年月日変更をしたことはない', type: 'checkbox', required: true },
    ]
  },
  'login_default': {
    skill: '',
    message: null,
    form: [
      { id: 'loginUserInquiryCategory', name: 'お問い合わせカテゴリ', type: 'select', required: true, selectItemsKey: 'loginUserInquiryCategory' },
      { id: 'inquiryContent', name: 'お問い合わせ内容詳細', placeholder: '例)注文した商品のキャンセル方法がわからず困っています。誤って注文してしまったのでキャンセルしたいです。', type: 'textarea', additionalMessageKey: 'noPurchaseHistory', required: true, maxLength: 100 },
      { id: 'selectedOrderNumbers', name: '注文を選択してください', type: 'purchaseHistory', required: false, logFormKey: 'orderNumber' },
      { id: 'disclaimer', alias: '免責確認', name: 'お問い合わせ内容によっては、楽天市場の各グループ会社/出店店舗へお客様の情報を共有します。場合により共有先からのご連絡となりますことをご了承ください。', type: 'checkbox', required: true },
    ]
  },
  'login_id_change_birthday': {
    skill: 'ID',
    message: 'forChangeBirthdayWithLogin',
    form: [
      { id: 'loginUserInquiryCategory', name: 'お問い合わせカテゴリ', type: 'select', required: true, selectItemsKey: 'birthDayChangeCategory' },
      { id: 'birthDayNew', name: '正しい生年月日(西暦)', placeholder: '例)19990110', type: 'text', required: true, maxLength: 8, regexp: '^(?!([2２][1235679１２３５６７９]|[1１][01345789０１３４５７８９])[0０][0０][0０][2２][2２][9９])(([12１２][0-9０-９]{3}([0０][1１]|[0０][3３]|[0０][5５]|[0０][7７]|[0０][8８]|[1１][0０]|[1１][2２])([0０][1-9１-９]|[12１２][0-9０-９]|[3３][01０１]))|([12１２][0-9０-９]{3}([0０][4４]|[0０][6６]|[0０][9９]|[1１][1１])([0０][1-9１-９]|[12１２][0-9０-９]|[3３][0０]))|([12１２][0-9０-９]{3}[0０][2２]([0０][1-9１-９]|[1１][0-9０-９]|[2２][0-8０-８]))|([12１２][0-9０-９]([02468０２４６８][048０４８]|[13579１３５７９][26２６])[0０][2２][2２][9９]))$' },
      { id: 'birthDayChangeReason', name: '変更理由', type: 'select', required: true, selectItemsKey: 'birthDayChangeReason' },
      { id: 'confirmChangeBirthDay', name: '過去にお問い合わせで生年月日変更をしたことはない', type: 'checkbox', required: true },
    ]
  }
}

const selectItemsMap = {
  birthDayChangeReason: [
    { item: '楽天モバイルの契約のため', value: '楽天モバイルの契約のため' },
    { item: '楽天証券を利用するため', value: '楽天証券を利用するため' },
    { item: '楽天銀行を利用するため', value: '楽天銀行を利用するため' },
    { item: 'その他', value: 'その他' }
  ],
  loginUserInquiryCategory: [
    { item: '楽天会員登録/ログイン', value: 'ID' },
    { item: 'ポイント/キャンペーン', value: 'PNT' },
    { item: 'お買い物に関するトラブル', value: 'SHP' },
    { item: '決済', value: 'PAY' },
    { item: 'その他', value: 'TEC' }
  ],
  birthDayChangeCategory: [
    { item: '生年月日変更', value: 'ID' }
  ]
}

module.exports = {
  settings,
  forms,
  selectItemsMap
}