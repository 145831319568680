import Dispatcher from "../Dispatcher"

export const keys = {
  set: Symbol("user-info:set"),
}

export default {
  set(item, value){
    Dispatcher.dispatch({
      type: keys.set,
      item,
      value,
    })
  },
}