import React from 'react';
import i18n from "../i18n";

const FormSubmitButton = ({ disabled, onSubmitModal }) => {
  return (
    <button
      type='button'
      id='modalSubmitButton'
      className={`modalInput__button modalInput__submit ${disabled ? 'modalInputButton__disabled' : ''}`}
      onClick={onSubmitModal}
      disabled={disabled}
    >
      {i18n.t('sendButton')}
    </button>
  )
}

export default FormSubmitButton;
