import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/HumanChatStatusActions"

class HumanChatStatusStore extends ReduceStore {

  getInitialState() {
    return { type: keys.initialize }
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.isRequesting:
      case keys.isConnected:
      case keys.isTimeout:
      case keys.isTransfering:
      case keys.isDisconnectedByUser:
      case keys.isDisconnectedByAgent:
      case keys.initialize:
        return action

      default:
        return state
    }
  }
}

export default new HumanChatStatusStore(Dispatcher)
