import { ReduceStore } from "flux/utils"
import Dispatcher from "../Dispatcher"
import { keys } from "../actions/FormFormatActions"
import { forms } from "../config/settings"

class ModalMessageStore extends ReduceStore {

  getInitialState() {
    return ''
  }

  reduce(state, action) {
    switch (action.type) {
      case keys.set:
        return  forms[action.formKey].message
      default:
        return state
    }
  }
}

export default new ModalMessageStore(Dispatcher)
