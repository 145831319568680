import Dispatcher from "../Dispatcher"

export const keys = {
  initialize: Symbol("status:initialize"),
  connect: Symbol("status:connect"),
  error: Symbol("status:error"),
  disconnect: Symbol("status:disconnect"),
  reconnect: Symbol("status:reconnect"),
  reconnectAttempt: Symbol("status:reconnect-attempt"),
  reconnecting: Symbol("status:reconnecting"),
  reconnectError: Symbol("status:reconnect-error"),
  reconnectFailed: Symbol("status:reconnect-failed"),
}

export default {
  initialize(){
    Dispatcher.dispatch({
      type: keys.initialize,
      text: '',
      classification: ''
    })
  },
  connect(){
    Dispatcher.dispatch({
      type: keys.connect,
      text: 'statusMessage.connect',
      classification: 'is-complete'
    })
  },
  error(){
    Dispatcher.dispatch({
      type: keys.error,
      text: 'statusMessage.connectError',
      classification: 'is-error'
    })
  },
  disconnect(){
    Dispatcher.dispatch({
      type: keys.disconnect,
      text: 'statusMessage.disconnect',
      classification: 'is-complete'
    })
  },
  reconnect(){
    Dispatcher.dispatch({
      type: keys.reconnect,
      text: 'statusMessage.reconnect',
      classification: 'is-processing'
    })
  },
  reconnectAttempt(){
    Dispatcher.dispatch({
      type: keys.reconnectAttempt,
      text: 'statusMessage.tryReconnect',
      classification: 'is-alert'
    })
  },
  reconnecting(data){
    Dispatcher.dispatch({
      type: keys.reconnecting,
      text: 'statusMessage.reconnecting',
      data,
      classification: 'is-processing'
    })
  },
  reconnectError(){
    Dispatcher.dispatch({
      type: keys.reconnectError,
      text: 'statusMessage.reconnectError',
      classification: 'is-error'
    })
  },
  reconnectFailed(){
    Dispatcher.dispatch({
      type: keys.reconnectFailed,
      text: 'statusMessage.reconnectFailed',
      classification: 'is-error'
    })
  }
}