import Dispatcher from "../Dispatcher"
import Socket from "../Socket"

export const keys = {
  start: Symbol("socket:start"),
  restart: Symbol("socket:restart"),
  report: Symbol("socket:report"),
  switch: Symbol("socket:switch"),
  switchRequest: Symbol("socket:switch-request"),
  inputMailAddress: Symbol("socket:input-mail-address"),
  postMessage: Symbol("socket:post-mssage"),
  cancelReception: Symbol("socket:cancel-reception"),
  postDetail: Symbol("socket:post-detail"),
  getMessage: Symbol("socket:get-message"),
  getFaq: Symbol("socket:get-faq"),
  getSuggestion: Symbol("socket:get-suggestion"),
  abandonChat: Symbol("socket:abandon-chat"),
  disconnectChat: Symbol("socket:disconnect-chat"),
  checkChatDisconnect: Symbol("socket:check-chat-disconnect"),
  close: Symbol("socket:close"),
  connectMonitor: Symbol("socket:connnect-monitor"),
  uploadFile: Symbol("socket:upload-file"),
  uploadFileSizeOver: Symbol("socket:upload-file-size-over"),
  linkOpened: Symbol("socket:link-opened")
}

export default {
  startBot(opponent='bot', conversationCase='', clientId, shopId='', orderNumber='', chatSessionId, healthChecker='test', pageUrl, urlPath, referrer, id, lang='ja', facilityNumber, isInputEnabled) {
    Dispatcher.dispatch({
      type: keys.start
    })

    Socket.startBot(opponent, conversationCase, clientId, shopId, orderNumber, chatSessionId, healthChecker, pageUrl, urlPath, referrer, id, lang, facilityNumber, isInputEnabled)
  },
  restartBot(){
    Dispatcher.dispatch({
      type: keys.restart
    })
    Socket.restartBot();
  },
  report({timing="",place="",text="",action=""}){
    Socket.report({timing, place, text, action})
  },
  postMessage(opponent, text, inputType, contents, questionnaire, userInfo, isProduction, session, lastFrom) {
    Dispatcher.dispatch({
      type: keys.postMessage,
      text,
    })
    Socket.postMessage(opponent, text, inputType, contents, questionnaire, userInfo, isProduction, session, lastFrom)
  },
  linkOpened({targetLink}){
    Dispatcher.dispatch({
      type: keys.linkOpened,
      targetLink,
    })
    Socket.linkOpened({targetLink})
  },

  cancelReception(){
    Dispatcher.dispatch({
      type: keys.cancelReception,
    })
    Socket.cancelReception()
  },

  postDetail({opponent, info, skill, contents=[], questionnaire={}, isCancel}) {
    Dispatcher.dispatch({
      type: keys.postDetail,
      info,
    })
    Socket.postDetail({opponent, info, skill, contents, questionnaire, isCancel})
  },

  reportUserAction({timing,place,content,action}) {
    Socket.reportUserAction({timing,place,content,action})
  },

  sendUserTextAsSneakPeak(caseId,staffId,text){
    Socket.sendUserTextAsSneakPeak(caseId,staffId,text)
  },

  uploadFile(filename, filetype, filesize, data_uri, id, sessionId, caseId, file, lastFrom) {
    Dispatcher.dispatch({
      type: keys.uploadFile,
      filename,
      filetype,
      filesize,
      data_uri,
      id,
      sessionId,
      caseId,
      file,
      lastFrom
    })

    Socket.uploadFile(filename, filetype, filesize, data_uri, id, sessionId, caseId, file, lastFrom)
  },

  uploadFileSizeOver(id) {
    Dispatcher.dispatch({
      type: keys.uploadFileSizeOver,
    })

    Socket.uploadFileSizeOver(id)
  },

  getFaq(text) {
    Dispatcher.dispatch({
      type: keys.getFaq,
      text,
    })

    Socket.getFaq(text)
  },

  getSuggestion(text) {
    Dispatcher.dispatch({
      type: keys.getSuggestion,
      text,
    })

    Socket.getSuggestion(text)
  },
  abandonChat(caseId, sessonId){
    Dispatcher.dispatch({
      type: keys.abandonChat,
    })
    Socket.abandonChat(caseId, sessonId)
  },
  disconnectChat(caseId,sessionId){
    Dispatcher.dispatch({
      type: keys.disconnectChat,
    })
    Socket.disconnectChat(caseId,sessionId)
  },
  checkChatDisconnect() {
    Dispatcher.dispatch({
      type: keys.checkChatDisconnect,
    })
    Socket.checkChatDisconnect()
  },
  connectMonitor(caseId,sessionId){
    Socket.connectMonitor(caseId,sessionId)
  },
  sendUserTyping(){
    Socket.sendUserTyping()
  },
  sendUserNotTyping(){
    Socket.sendUserNotTyping()
  },

  close() {
    Dispatcher.dispatch({
      type: keys.close,
    })

    Socket.close()
  },

  //test
  clearSession(sessionId){
    Socket.clearSession(sessionId)
  }
}
